import React, { useState, useEffect } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { useDebounce } from "../../../_metronic/helpers";

interface SearchInputProps {
  placeholder: string;
  onSearch: (query: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchTerm: string = useDebounce(searchQuery, 50) || "";

  useEffect(() => {
    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearch]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="card-toolbar d-flex align-items-center justify-content-end w-100">
      <div className="d-flex align-items-center position-relative">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-6"
        />
        <input
          type="text"
          data-kt-user-table-filter="search"
          className="form-control form-control-solid w-250px ps-14"
          placeholder={placeholder}
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
    </div>
  );
};

export default SearchInput;
