/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import API from '../../../../utils/apiProvider'
import {useAuth} from '../../auth'
import {Empty, Image, Spin} from 'antd'
import RupiahFormat from '../../global/Formatrupiah'
import CountUp from 'react-countup'

type Product = {
  id: number
  photo: string | null
  name: string
  merk: string
  condition: string
  description: string
  price: number
  stock: number
  sold: number
  is_active: boolean
  shop: {
    id: number
    photo: string | null
    name: string
    phone: string
    address: string
  }
}
interface Shop {
  id: number
  photo: string
  name: string
}

interface TopSellingProduct {
  id: number
  photo: string
  name: string
  price: string
  sold: number
  shop: Shop
}

const TopSellingProduct: React.FC = () => {
  const {currentUser} = useAuth()
  const [dataTopsellingProduct, setTopSellingProduct] = useState<TopSellingProduct[]>([])
  const [loading, setLoading] = useState(false)
  const [range, setRange] = useState('all')
  const [zeroData, setZeroData] = useState('')

  useEffect(() => {
    if (currentUser?.token) {
      getTopSellingProduct()
    }
  }, [currentUser])

  const getTopSellingProduct = async () => {
    setLoading(true)
    const resNewProductHome = await API.GetHome(currentUser?.token, range)
    if (resNewProductHome.data) {
      setTopSellingProduct(resNewProductHome.data.sellings)
      setZeroData(resNewProductHome.message)
    } else {
      setZeroData(resNewProductHome.message)
    }
    setLoading(false)
  }
  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text
    }
    return text.substring(0, maxLength) + '...'
  }

  const formatToRupiah = (price: number) => {
    const formatted = `Rp ${Number(price).toLocaleString('id-ID')}`
    return formatted
  }

  const formatPrice = (price: string | null) => {
    if (!price) {
      return ''
    }
    const [min, max] = price.split(' - ')
    const formattedMin = `Rp ${Number(min).toLocaleString('id-ID')}`
    const formattedMax = `Rp ${Number(max).toLocaleString('id-ID')}`
    return `${formattedMin} - ${formattedMax}`
  }
  return (
    <div className='card'>
      <div className='card-flush h-xl-100 '>
        <div className='card-header pt-7 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-gray-800'>Produk Terlaris</span>
          </h3>
        </div>
        <div className='card-body pt-4'>
          <div className='table-responsive'>
            <table className='table table-row-dashed align-middle gs-0 gy-4 my-0'>
              <thead>
                <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                  <th className='p-0 w-50px pb-1 text-center'>PRODUK</th>
                  <th className='ps-0 min-w-140px' />
                  <th className='text-end min-w-140px p-0 pb-1'>HARGA</th>
                </tr>
              </thead>
              <tbody>
                {!dataTopsellingProduct.length ? (
                  loading ? (
                    <div className='loading-container'>
                      <Spin />
                    </div>
                  ) : (
                    <tr>
                      <td colSpan={8} className='text-center text-muted'>
                        <Empty
                          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                          imageStyle={{height: 60}}
                          description={<span className='text-muted fw-bold'>Data tidak ada</span>}
                        />
                      </td>
                    </tr>
                  )
                ) : (
                  dataTopsellingProduct.map((product) => (
                    <tr key={product.id}>
                      <td>
                        <Image
                          src={product.photo}
                          className='rounded object-fit-cover'
                          style={{width: '50px', height: '50px'}}
                        />
                      </td>
                      <td className='ps-0'>
                        <span className='text-gray-800 fw-bold  mb-1 fs-6 text-start pe-0'>
                          {truncateText(product.name, 28)}
                        </span>
                        <span className='text-gray-500 fw-semibold fs-7 d-block text-start ps-0'>
                          Terjual: <CountUp end={product.sold} duration={2} separator='.' />
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-bold d-block fs-6 ps-0 text-end'>
                          {product.price && typeof product.price === 'number'
                            ? formatToRupiah(product.price)
                            : formatPrice(product.price)}
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export {TopSellingProduct}
