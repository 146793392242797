import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";
import { Skeleton } from "antd";

const PercentValidationSchema = Yup.object().shape({
  label: Yup.string().required("Label diperlukan"),
  biaya: Yup.number()
    .typeError("Biaya harus berupa angka")
    .required("Biaya diperlukan")
    .min(0, "Biaya harus lebih besar dari atau sama dengan 0"),
  persentase: Yup.number()
    .typeError("Persentase biaya harus berupa angka")
    .required("Persentase biaya diperlukan")
    .min(0, "Persentase biaya harus lebih besar dari atau sama dengan 0")
    .max(100, "Persentase biaya tidak boleh lebih dari 100"),
});

type FeeData = {
  id: number;
  label: string;
  cost: number | null;
  percent: number | null;
};

const FeeComponent: React.FC = () => {
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [loading, setLoading] = useState<boolean>(false);
  const [feeData, setFeeData] = useState<FeeData[]>([]);

  const [showNotifForm, setshowNotifForm] = useState<boolean>(true);
  const [showEkspedisiForm, setshowEkspedisiForm] = useState<boolean>(true);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const label1 = "Transaction";
  const label2 = "Expedition";
  const [loadingSubmit, setLoadingSubmit] = useState<number | null>(null);

  useEffect(() => {
    fetchData();
  }, [token]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlert]);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlertError]);

  const fetchData = async () => {
    setLoading(true);
    const res = await API.GetFee(token);
    console.log(res, "fee");

    if (res && res.data) {
      const sortedFeeData = res.data.sort(
        (a: FeeData, b: FeeData) => a.id - b.id
      );
      setFeeData(sortedFeeData);
    } else {
      console.error("Error fetching fee data:", res);
    }
    setLoading(false);
  };

  const updateFeeDataInState = (updatedFee: FeeData) => {
    setFeeData((prevData) =>
      prevData.map((item) => (item.id === updatedFee.id ? updatedFee : item))
    );
  };

  const formik1 = useFormik({
    initialValues: {
      label: feeData[0]?.label || "",
      biaya: feeData[0]?.cost !== null ? feeData[0]?.cost.toString() : "",
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      label: Yup.string().required("Label diperlukan"),
      biaya: Yup.number()
        .typeError("Biaya harus berupa angka")
        .required("Biaya diperlukan")
        .min(0, "Biaya harus lebih besar dari atau sama dengan 0"),
    }),
    onSubmit: async (values) => {
      setLoadingSubmit(feeData[0].id);
      const updateFeeData = {
        label: label1,
        cost: parseInt(values.biaya, 10),
        percent: feeData[0].percent,
      };
      const res = await API.UpdateFee(updateFeeData, token, feeData[0].id);
      if (res) {
        updateFeeDataInState({
          ...feeData[0],
          cost: parseInt(values.biaya, 10),
        });
        setshowNotifForm(true);
        setShowAlert(true);
        setSuccessMessage(res.message);
        await fetchData();
      } else {
        setShowAlertError(true);
        setErrorMessage(res.message);
      }
      setLoadingSubmit(null);
    },
  });

  const formik2 = useFormik({
    initialValues: {
      label: feeData[1]?.label || "",
      persentase:
        feeData[1]?.percent !== null ? feeData[1]?.percent.toString() : "",
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      label: Yup.string().required("Label diperlukan"),
      persentase: Yup.number()
        .typeError("Persentase biaya harus berupa angka")
        .required("Persentase biaya diperlukan")
        .min(0, "Persentase biaya harus lebih besar dari atau sama dengan 0")
        .max(100, "Persentase biaya tidak boleh lebih dari 100"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setLoadingSubmit(feeData[1].id);

      const updateFeeData = {
        label: label2,
        percent: values.persentase,
      };
      const res = await API.UpdateFee(updateFeeData, token, feeData[1].id);
      if (res) {
        updateFeeDataInState({
          ...feeData[1],
          percent: parseInt(values.persentase, 10),
        }); // Update the state with the parsed value
        setshowEkspedisiForm(true);
        setshowNotifForm(true);
        setShowAlert(true);
        setSuccessMessage(res.message);

        await fetchData();
      } else {
        setShowAlertError(true);
        setErrorMessage(res.message);
      }
      setLoadingSubmit(null);
    },
  });

  return (
    <>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}
      {feeData.map((fee, index) => (
        <div className="card mb-5 mb-xl-10" key={index}>
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle={`collapse show`}
            data-bs-target={`#kt_account_signin_method${index}`}
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">
                {fee.label === "Transaction" ? "Transaksi" : "Ekspedisi"}
              </h3>
            </div>
          </div>
          <div
            id={`kt_account_signin_method${index}`}
            className={`collapse show`}
          >
            <div className="card-body border-top p-9">
              <div className="d-flex flex-wrap align-items-center">
                <div
                  id="kt_signin_email"
                  className={` ${showNotifForm && "d-none"}`}
                >
                  <div className="fs-6 fw-bolder mb-1">
                    {fee.cost !== null ? "Biaya" : "Persentase"}
                  </div>
                  <div className="fw-bold text-gray-600">
                    {fee.cost !== null ? fee.cost : fee.percent}
                  </div>
                </div>

                <div
                  id="kt_signin_email_edit"
                  className={`flex-row-fluid ${!showNotifForm && "d-none"}`}
                >
                  <form
                    onSubmit={
                      fee.cost !== null
                        ? formik1.handleSubmit
                        : formik2.handleSubmit
                    }
                    id="kt_signin_change_email"
                    className="form"
                    noValidate
                  >
                    <div className="col mb-6">
                      <div className="col mb-6">
                        <div className="fv-row mb-0">
                          <label
                            htmlFor="emailaddress"
                            className="form-label fs-6 fw-bolder mb-3"
                          >
                            {fee.cost !== null ? "Biaya" : "Persentase"}:
                          </label>
                          {loadingSubmit === fee.id ? (
                            <Skeleton.Input
                              style={{ width: "100%", height: "45px" }}
                              active
                              block
                            />
                          ) : (
                            <>
                              <input
                                type={fee.cost !== null ? "number" : "number"}
                                className={`form-control form-control-lg form-control-solid ${
                                  formik1.errors.biaya ||
                                  formik2.errors.persentase
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id={fee.cost !== null ? "biaya" : "persentase"}
                                placeholder={
                                  fee.cost !== null
                                    ? "Masukkan biaya..."
                                    : "Masukkan persentase..."
                                }
                                {...(fee.cost !== null
                                  ? formik1.getFieldProps("biaya")
                                  : formik2.getFieldProps("persentase"))}
                              />
                              {fee.cost !== null ? (
                                <div className="invalid-feedback">
                                  {formik1.errors.biaya}
                                </div>
                              ) : (
                                <div className="invalid-feedback">
                                  {formik2.errors.persentase}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <button
                        type="submit"
                        className="btn btn-primary me-2 px-6"
                        disabled={loadingSubmit === fee.id}
                      >
                        {loadingSubmit === fee.id
                          ? "Sabar boss..."
                          : "Simpan Perubahan"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export { FeeComponent };
