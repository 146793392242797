/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useAuth } from "../auth";
import API from "../../../utils/apiProvider";
import { Pagination } from "../global/Pagination";
import SearchInput from "../global/SearchInput";
import { KTCardBody } from "../../../_metronic/helpers";
import { Empty, Spin } from "antd";

interface LogEntry {
  id: number;
  app_type: string;
  app_version: string;
  user: string;
  ip_address: string;
  endpoint: string;
  http_status: string;
  screen: string;
  message: string;
}

const statusOptions = [
  { label: "Seller", value: "seller" },
  { label: "Buyer", value: "buyer" },
  { label: "Ekspedisi", value: "expedition" },
  { label: "Admin", value: "panel" },
];

const LogPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [meta, setMetaData] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [logData, setLogData] = useState<LogEntry[]>([]);
  const [zeroData, setZeroData] = useState("");
  const [appType, setAppType] = useState("seller");
  const [httpStatus, setHttpStatusData] = useState("");
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    if (token) {
      getLogEntry();
    }
  }, [token, appType, httpStatus, meta.page, meta.per_page]);

  const getLogEntry = async () => {
    setLoading(true);

    const res = await API.GetLog(
      token,
      appType,
      httpStatus,
      meta.page,
      meta.per_page
    );

    if (res.data.length > 0) {
      setLogData(res.data);
      setMetaData(res.meta);
      setZeroData("");
    } else {
      setLogData([]);
      setZeroData(res.data.message);
      setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
    }
    setLoading(false);
  };

  const handleSearch = (query: string) => {
    setHttpStatusData(query);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleFilterChangeStatus = (status: string) => {
    setAppType(status);
  };

  return (
    <div className="card mb-xl-10">
      <div className="card mb-xl-8">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">List Error Log</h3>
          </div>
        </div>
        <div className="separator separator"></div>
        {/* <div className="card-header border-0 pt-6">
          <div className="card-toolbar d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center position-relative">
              <SearchInput placeholder="Search" onSearch={handleSearch} />
            </div>
          </div>
        </div> */}

        <KTCardBody className="py-4">
          <div className="d-flex gap-2 pb-6">
            {statusOptions.map((status) => (
              <button
                key={status.value}
                className={`btn btn-sm btn-light-primary ${
                  appType === status.value ? "active" : ""
                }`}
                onClick={() => handleFilterChangeStatus(status.value)}
              >
                {status.label}
              </button>
            ))}
          </div>
          <div className="table-responsive">
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <thead>
                <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                  <th className="min-w-60px rounded-start">No</th>
                  <th className="min-w-125px">User</th>
                  <th className="min-w-125px">Http Status</th>
                  <th className="min-w-250px">Endpoint</th>
                  <th className="min-w-125px">App Tipe</th>
                  <th className="min-w-125px">App Versi</th>
                  <th className="min-w-125px">IP Addres</th>
                  <th className="min-w-125px">Screen</th>
                  <th className="pe-6 rounded-end">Message</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan={10}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      <Spin size="default" />
                    </td>
                  </tr>
                ) : logData.length > 0 ? (
                  logData.map((log, index) => (
                    <>
                      <tr key={log.id}>
                        <td className="text-center">
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center flex-column">
                              <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                                {startIndex + index + 1}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <span className="text-dark fw-bold fs-6 me-2">
                            {log.user}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="badge badge-light-danger me-4">
                            {log.http_status}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="text-dark fw-bold fs-6 me-2">
                              {log.endpoint}
                            </span>
                          </div>
                        </td>
                        <td className="text-center">{log.app_type}</td>
                        <td className="text-center">{log.app_version}</td>
                        <td className="text-center">{log.ip_address}</td>

                        <td>{log.screen}</td>
                        <td>{log.message}</td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      {zeroData ? (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{ height: 60 }}
                          description={
                            <span className="text-muted fw-bold">
                              {zeroData}
                            </span>
                          }
                        />
                      ) : null}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              totalRecords={meta.total}
              recordsPerPage={meta.per_page}
              currentPage={meta.page}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          </div>
        </KTCardBody>
      </div>
    </div>
  );
};

export { LogPage };
