/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG, useDebounce } from "../../../../_metronic/helpers";
import API from "../../../../utils/apiProvider";
import { useAuth } from "../../auth";
import { Link } from "react-router-dom";
import { Pagination } from "../../global/Pagination";
import { Empty, Spin, Table } from "antd";
import SearchInput from "../../global/SearchInput";
import RupiahFormat from "../../global/Formatrupiah";

type Props = {
  className: string;
};

type Transaction = {
  id: number;
  code: string;
  shop: {
    id: number;
    photo: string;
    name: string;
  };
  product: Array<{
    id: number;
    photo: string;
    name: string;
    variant: string | null;
    qty: number;
    price: number;
  }>;
  shipping: {
    name: string;
    phone: number;
    courier: string;
    service: string;
    resi: string | null;
    address: string;
    status: string;
  };
  payment: {
    bank: string;
    type: string;
    number: string;
    url: string;
    expired: string;
  };
  shipping_cost: number;
  extra_cost: number;
  subtotal: number;
  fee_payment: number;
  fee: number;
  grand_total: number;
  status: string;
  date: string;
};

interface Meta {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

const TransactionComponent: React.FC<Props> = ({ className }) => {
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [zeroData, setZeroData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [metaData, setMetaData] = useState<Meta>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });

  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const newIndex = (metaData.page - 1) * metaData.per_page;
    setStartIndex(newIndex);
  }, [metaData.page, metaData.per_page]);

  useEffect(() => {
    if (currentUser?.token) {
      fetchTransactions();
    }
  }, [currentUser, metaData.page, searchQuery, metaData.per_page]);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const resTransaction = await API.GetTransaction(
        token,
        metaData.page,
        searchQuery,
        metaData.per_page
      );
      if (resTransaction && resTransaction.data && resTransaction.meta) {
        setTransactionsData(resTransaction.data);
        setMetaData(resTransaction.meta);
      } else {
        setTransactionsData([]);
        setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
        setZeroData(resTransaction.data.message);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= metaData.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <div className={`card ${className}`}>
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">List Transaksi</h3>
        </div>
      </div>
      <div className="separator separator"></div>
      <div className="card-header border-0 pt-6">
        <div className="card-toolbar d-flex align-items-center justify-content-end w-100">
          <div className="d-flex align-items-center position-relative">
            <SearchInput placeholder="Search" onSearch={handleSearch} />
          </div>
        </div>
      </div>

      <div className="card-body py-3">
        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          >
            <thead>
              <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                <th className="min-w-60px rounded-start">No</th>
                <th className="min-w-125px">Kode Transaksi</th>
                <th className="min-w-125px">Tanggal</th>
                <th className="min-w-125px">Status</th>
                <th className="min-w-100x">Total</th>
                <th className="pe-4 min-w-125px rounded-end">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan={8}
                    className="text-center text-muted fw-bold fs-6"
                  >
                    <Spin size="default" />
                  </td>
                </tr>
              ) : transactionsData.length > 0 ? (
                transactionsData.map((transaction, index) => (
                  <tr key={transaction.id}>
                    <td className="text-center">
                      <span className="text-dark fw-bold mb-1 fs-6">
                        {startIndex + index + 1}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark fw-bold d-block mb-1 fs-6">
                        {transaction.code}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        {transaction.date}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        <span
                          className={`badge ${
                            transaction.status === "pending"
                              ? "badge-light-primary"
                              : transaction.status === "nego"
                              ? "badge-light-warning"
                              : transaction.status === "paid"
                              ? "badge-light-info"
                              : transaction.status === "process"
                              ? "badge-light-warning"
                              : transaction.status === "sent"
                              ? "badge-light-success"
                              : transaction.status === "success"
                              ? "badge-light-success"
                              : "badge-light-danger"
                          }`}
                        >
                          {transaction.status === "pending"
                            ? "Pending"
                            : transaction.status === "nego"
                            ? "Negosiasi"
                            : transaction.status === "paid"
                            ? "Diproses"
                            : transaction.status === "process"
                            ? "Dikemas"
                            : transaction.status === "sent"
                            ? "Dikirim"
                            : transaction.status === "success"
                            ? "Selesai"
                            : "Dibatalkan"}
                        </span>
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        <RupiahFormat value={transaction.grand_total} />
                      </span>
                    </td>
                    <td className="text-center">
                      <Link
                        to={`/marketplace/transaksi/detail/${transaction.id}`}
                        state={{
                          id: transaction.id,
                          data: transaction,
                        }}
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                      >
                        <KTSVG
                          path="/media/icons/duotune/ecommerce/ecm008.svg"
                          className="svg-icon-3"
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={8}
                    className="text-center text-muted fw-bold fs-6"
                  >
                    {zeroData ? (
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 60 }}
                        description={
                          <span className="text-muted fw-bold">{zeroData}</span>
                        }
                      />
                    ) : null}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            totalRecords={metaData.total}
            recordsPerPage={metaData.per_page}
            currentPage={metaData.page}
            onPageChange={handlePageChange}
            onRecordsPerPageChange={handleRecordsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export { TransactionComponent };
