import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useAuth} from '../../../../app/modules/auth'
import API from '../../../../utils/apiProvider'
import {useThemeMode} from '../../../../_metronic/partials'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import CountUp from 'react-countup'
import {Empty, Spin} from 'antd'

type IncomeData = {
  dates: string[]
  totals: number[]
}

type RangeType = '7' | '30' | 'all'

const IncomeCharts: React.FC = () => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const {currentUser} = useAuth()

  const [range, setRange] = useState<RangeType>('all')
  const [dataIncome, setDataIncome] = useState<IncomeData>({
    dates: [],
    totals: [],
  })
  const [dataTotalIncome, setDataTotalIncome] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (currentUser?.token) {
      getDataWidget()
    }
  }, [currentUser, range])

  useEffect(() => {
    if (chartRef.current && dataIncome.totals.length > 0) {
      const chart = new ApexCharts(
        chartRef.current,
        getChartOptions(chartRef.current.clientHeight, dataIncome.dates, dataIncome.totals)
      )
      chart.render()

      return () => {
        chart.destroy()
      }
    }
  }, [chartRef, dataIncome])

  const getDataWidget = async () => {
    setLoading(true)
    try {
      const res = await API.GetHome(currentUser?.token, range)
      if (res.data) {
        const formattedTransactions = formatDates(res.data.incomes)
        setDataIncome(formattedTransactions)
        setDataTotalIncome(res.data.income_total)
      } else {
        console.error('Error fetching data:', res)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const formatDates = (data: IncomeData): IncomeData => {
    const formattedDates = data.dates.map((dateString) => {
      const date = new Date(dateString)
      return `${date.getDate()} ${getMonthName(date.getMonth())}`
    })

    return {
      ...data,
      dates: formattedDates,
    }
  }

  const getMonthName = (month: number): string => {
    const months = [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ]
    return months[month]
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const selectRange = (selectedRange: RangeType) => {
    setRange(selectedRange)
    setIsOpen(false)
  }

  const rangeText: Record<RangeType, string> = {
    '7': '7 Hari',
    '30': '30 Hari',
    all: 'Semua',
  }

  const getRangeText = (range: RangeType): string => {
    switch (range) {
      case '7':
        return `Pemasukan dari 7 hari terakhir`
      case '30':
        return `Pemasukan dari 30 hari terakhir`
      case 'all':
        return `Pemasukan dari semua transaksi`
      default:
        return ''
    }
  }

  return (
    <div className='card card-flush h-xl-100'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-gray-900'>Pemasukan</span>
        </h3>
        <div className='card-toolbar'>
          <div
            className='btn btn-sm btn-light d-flex align-items-center px-4'
            onClick={toggleDropdown}
          >
            <div className='text-gray-600 fw-bold'>{rangeText[range]}</div>
            <i className='fas bi-chevron-down fa-sm text-gray-500 lh-0 ms-2'></i>
          </div>
          {isOpen && (
            <div className='dropdown-menu dropdown-menu-end show'>
              <span className='dropdown-header'>Pilih rentang waktu</span>
              <button className='dropdown-item' onClick={() => selectRange('7')}>
                7 hari terakhir
              </button>
              <button className='dropdown-item' onClick={() => selectRange('30')}>
                30 hari terakhir
              </button>
              <button className='dropdown-item' onClick={() => selectRange('all')}>
                Semua
              </button>
            </div>
          )}
        </div>
      </div>

      <div className='card-body pb-0 pt-4'>
        {loading ? (
          <Spin />
        ) : dataIncome.dates.length > 0 && dataIncome.totals.length > 0 ? (
          <>
            <div className='mb-2'>
              <div className='d-flex mb-2'>
                <span className='fs-4 fw-semibold text-gray-500 me-1'>Rp</span>
                <span className='fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2'>
                  <CountUp end={dataTotalIncome} duration={2} />
                </span>
              </div>
              <span className='fs-6 fw-semibold text-gray-500'>{getRangeText(range)}</span>
            </div>
            <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '300px'}}></div>
          </>
        ) : (
          <Empty
            image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
            imageStyle={{height: 150}}
            description={<span className='text-muted fw-bold'>Data tidak ada</span>}
          />
        )}
      </div>
    </div>
  )
}

export {IncomeCharts}

function getChartOptions(height: number, dates: string[], incomes: number[]): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseprimaryColor = getCSSVariableValue('--bs-success')
  const basesuccessColor = getCSSVariableValue('--bs-success')

  const formatRupiah = (value: number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(value)
  }

  return {
    series: [
      {
        name: 'Pemasukan',
        data: incomes.map((total, index) => ({
          x: dates[index],
          y: total,
        })),
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.2,
        stops: [15, 120, 100],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseprimaryColor, basesuccessColor],
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 5,
      labels: {
        rotate: 0,
        rotateAlways: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseprimaryColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },

    yaxis: {
      labels: {
        formatter: formatRupiah,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },

    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      y: {
        formatter: formatRupiah,
      },
      style: {
        fontSize: '12px',
      },
    },
    colors: [baseprimaryColor, basesuccessColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: [baseprimaryColor, basesuccessColor],
      strokeWidth: 3,
    },
  }
}
