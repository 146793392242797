/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {EditProfile} from '../../modules/settings/Profile/EditProfile'
import {UpdatePassword} from '../../modules/settings/Profile/UpdatePassword'
import {MegaMenu} from '../../../_metronic/layout/components/header/header-menus/MegaMenu'
import usePageTitle from '../../modules/global/PageTitle'

const AkunPage: FC = () => (
  <>
    <EditProfile />
    <UpdatePassword />
    {/* <div className='card mb-5 mb-xl-10'>
      <MegaMenu />
    </div> */}
  </>
)

const AkunWrapper: FC = () => {
  const intl = useIntl()
  usePageTitle('Akun')

  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <AkunPage />
    </>
  )
}

export {AkunWrapper}
