import React, { useState } from "react";
import { Navigate, Route, Routes, Outlet, useLocation } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { ListChat } from "./components/ListChat";
import { ChatHeader } from "./ChatHeader";

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: "",
    path: "",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const ChatPage = () => {
  const [selectedRole, setSelectedRole] = useState("ekspedisi");
  const location = useLocation();

  const handleRoleChange = (role: string) => {
    setSelectedRole(role);
  };

  return (
    <Routes>
      <Route
        element={
          <>
            <ChatHeader onRoleChange={handleRoleChange} />
            <Outlet />
          </>
        }
      >
        <Route
          path="ekspedisi"
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}></PageTitle>
              <ListChat role={selectedRole} />
            </>
          }
        />
        <Route
          path="penjual"
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}></PageTitle>
              <ListChat role={selectedRole} />
            </>
          }
        />
        <Route
          path="pembeli"
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}></PageTitle>
              <ListChat role={selectedRole} />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ChatPage;
