// AccountPage.tsx

import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {UserMultiPage} from './components/UserMultipage'
import usePageTitle from '../global/PageTitle'

const accountBreadcrumbs: Array<PageLink> = [
  {
    title: '',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const generateTransactionBreadcrumbs = (userType: string): Array<PageLink> => {
  const userTypePath = userType === 'pembeli' ? '/pengguna/pembeli' : '/pengguna/penjual'

  return [
    {
      title: userType === 'pembeli' ? '' : '',
      path: userTypePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Transaksi',
      path: `${userTypePath}/transaksi`,
      isSeparator: true,
      isActive: true,
    },
  ]
}

const AccountPage: React.FC = () => {
  usePageTitle('Pengguna')

  return (
    <Routes>
      <Route
        path='/manager/*'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadcrumbs}></PageTitle>
            <UserMultiPage pageType='manager' />
          </>
        }
      />
      <Route
        path='/akunting/*'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadcrumbs}></PageTitle>
            <UserMultiPage pageType='accounting' />
          </>
        }
      />
      <Route
        path='/adminekspedisi/*'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadcrumbs}></PageTitle>
            <UserMultiPage pageType='admin_exp' />
          </>
        }
      />
      <Route
        path='/ekspedisi/*'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadcrumbs}></PageTitle>
            <UserMultiPage pageType='exp' />
          </>
        }
      />
      <Route
        path='/pembeli/*'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadcrumbs}></PageTitle>
            <UserMultiPage pageType='buyer' />
          </>
        }
      />
      <Route
        path='/penjual/*'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadcrumbs}></PageTitle>
            <UserMultiPage pageType='seller' />
          </>
        }
      />
      <Route index element={<Navigate to='/pengguna/overview' />} />
    </Routes>
  )
}

export default AccountPage
