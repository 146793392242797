import React from 'react'

interface RupiahFormatProps {
  value: number
}

const formatToRupiah = (price: number) => {
  const formatted = `Rp ${Number(price).toLocaleString('id-ID')}`
  return formatted
}

const RupiahFormat: React.FC<RupiahFormatProps> = ({value}) => {
  return <span>{formatToRupiah(value)}</span>
}

export default RupiahFormat
