/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {FeeComponent} from '../../modules/settings/Fee/FeeComponent'
import {MegaMenu} from '../../../_metronic/layout/components/header/header-menus/MegaMenu'
import API from '../../../utils/apiProvider'
import {useAuth} from '../../modules/auth'
import usePageTitle from '../../modules/global/PageTitle'

type FeeData = {
  id: number
  label: string
  cost: number | null
  percent: number | null
}

const FeePage: FC = () => {
  return (
    <>
      <FeeComponent />
      {/* <MegaMenu /> */}
    </>
  )
}

const FeeWrapper: FC = () => {
  const intl = useIntl()
  usePageTitle('Biaya Penanganan')

  return (
    <>
      <PageTitle breadcrumbs={[]} />
      <FeePage />
    </>
  )
}

export {FeeWrapper}
