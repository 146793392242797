import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Select from "react-select";

interface UpdatePriceExpeditionModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleUpdatePrice: (data: { label: string; color: string }) => void;
  initialData: { label: string; color: string };
}

const colorOptions = [
  { value: "#44546b", label: "Biru Abu Gelap" },
  { value: "#4473c5", label: "Biru Langit" },
  { value: "#ed7d31", label: "Oranye" },
  { value: "#a5a5a5", label: "Abu-abu" },
  { value: "#ffc000", label: "Kuning" },
  { value: "#70ad46", label: "Hijau" },
  { value: "#012060", label: "Biru Tua" },
  { value: "#01b0f1", label: "Biru Terang" },
  { value: "#c00000", label: "Merah" },
  { value: "#7030a0", label: "Ungu" },
];

export const UpdatePriceExpeditionModal: React.FC<
  UpdatePriceExpeditionModalProps
> = ({ isOpen, onClose, handleUpdatePrice, initialData }) => {
  const [formData, setFormData] = React.useState<{
    label: string;
    color: string;
  }>(initialData);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleUpdatePrice(formData);
    onClose();
  };

  const handleColorChange = (selectedOption: any) => {
    setFormData({ ...formData, color: selectedOption.value });
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Harga Ekspedisi</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Label</Form.Label>
            <Form.Control
              type="text"
              value={formData.label}
              onChange={(e) =>
                setFormData({ ...formData, label: e.target.value })
              }
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Warna</Form.Label>
            <Select
              options={colorOptions}
              value={colorOptions.find(
                (option) => option.value === formData.color
              )}
              onChange={handleColorChange}
              formatOptionLabel={({ value, label }) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "24px",
                      height: "24px",
                      backgroundColor: value,
                      marginRight: "8px",
                      borderRadius: "50%",
                    }}
                  />
                  {label}
                </div>
              )}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-flex btn-sm btn-light-danger"
          onClick={onClose}
        >
          Batal
        </button>
        <button
          className="btn btn-flex btn-sm btn-danger"
          onClick={handleSubmit}
        >
          Simpan
        </button>
      </Modal.Footer>
    </Modal>
  );
};
