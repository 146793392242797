/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import usePageTitle from "../../modules/global/PageTitle";
import { PaymentComponent } from "../../modules/settings/Payment/PaymentComponent";

const FeePage: FC = () => {
  return (
    <>
      <PaymentComponent />
    </>
  );
};

const PaymentWrapper: FC = () => {
  const intl = useIntl();
  usePageTitle("Pembayaran");

  return (
    <>
      <PageTitle breadcrumbs={[]} />
      <FeePage />
    </>
  );
};

export { PaymentWrapper };
