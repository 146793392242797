/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import API from "../../../../utils/apiProvider";
import { useAuth } from "../../auth";
import { Empty, Image, Spin } from "antd";
import { Link } from "react-router-dom";

type Product = {
  id: number;
  photo: string;
  name: string;
  merk: string;
  condition: string;
  description: string;
  price: string;
  stock: number;
  sold: number;
  is_active: boolean;
  shop: {
    id: number;
    photo: string | null;
    name: string;
    phone: string;
    address: string;
  };
};

const NewProduct: React.FC = () => {
  const { currentUser } = useAuth();
  const [newProductHomeData, setNewProductHomeData] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser?.token) {
      getNewProductHome();
    }
  }, [currentUser]);

  const getNewProductHome = async () => {
    setLoading(true);
    const resNewProductHome = await API.GetHome(currentUser?.token, "all");
    if (resNewProductHome.data) {
      setNewProductHomeData(resNewProductHome.data.newest_products);
    } else {
      console.error("Error fetching home data:", resNewProductHome);
    }
    setLoading(false);
  };

  const formatToRupiah = (price: number) => {
    const formatted = `Rp ${Number(price).toLocaleString("id-ID")}`;
    return formatted;
  };

  const formatPrice = (price: string | null) => {
    if (!price) {
      return "";
    }
    const [min, max] = price.split(" - ");
    const formattedMin = `Rp ${Number(min).toLocaleString("id-ID")}`;
    const formattedMax = `Rp ${Number(max).toLocaleString("id-ID")}`;
    return `${formattedMin} - ${formattedMax}`;
  };

  return (
    <div className="card">
      <>
        <div className="card card-flush h-xl-100">
          <div className="card-header pt-7">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-gray-800">
                Produk Terbaru
              </span>
            </h3>
            <div className="card-toolbar">
              <Link
                to="/marketplace/semuaproduk"
                className="btn btn-sm btn-light"
              >
                <span className="text-gray-600 fw-bold me-4">
                  Lihat semua produk
                </span>
                <KTSVG
                  path="media/icons/duotune/arrows/arr001.svg"
                  className="svg-icon-muted svg-icon-1hx"
                />
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-row-dashed align-middle  gs-0 gy-3 my-0">
                <thead>
                  <tr className="fs-7 fw-bold text-gray-500 text-uppercase border-bottom-0">
                    <th className="p-0 pb-3 min-w-175px text-center">Produk</th>
                    <th className="p-0 pb-3 min-w-100px text-center">
                      kondisi
                    </th>
                    <th className="p-0 pb-3 min-w-100px text-center">stok</th>
                    <th className="p-0 pb-3 min-w-125px text-center">
                      terjual
                    </th>
                    <th className="p-0 pb-3 min-w-300px text-center">harga </th>
                    {/* <th className='p-0 pb-3 w-80px text-center'>detail</th> */}
                  </tr>
                </thead>
                <tbody>
                  {!newProductHomeData.length ? (
                    loading ? (
                      <Spin />
                    ) : (
                      <tr>
                        <td colSpan={8} className="text-center text-muted">
                          <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{ height: 60 }}
                            description={
                              <span className="text-muted fw-bold">
                                Data tidak ada
                              </span>
                            }
                          />
                        </td>
                      </tr>
                    )
                  ) : (
                    newProductHomeData.map((product) => (
                      <tr key={product.id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-50px me-3">
                              <Image
                                src={product.photo}
                                className="rounded"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-gray-800 fw-bold mb-1 fs-6">
                                {product.name}
                              </span>
                              <span className="text-gray-500 fw-semibold d-block fs-7">
                                {product.merk}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <span className="text-gray-600 fw-bold fs-6">
                            {product.condition === "new"
                              ? "Baru"
                              : product.condition === "second"
                              ? "Bekas"
                              : "Unknown Condition"}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="text-gray-600 fw-bold fs-6">
                            {product.stock}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="text-gray-600 fw-bold fs-6">
                            {product.sold}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="text-gray-600 fw-bold d-block fs-6">
                            {product.price && typeof product.price === "number"
                              ? formatToRupiah(product.price)
                              : formatPrice(product.price)}
                          </span>
                        </td>

                        {/* <td className='text-center'>
                          <Link
                            to='/marketplace/semuaproduk/edit-produk'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px'
                            state={{
                              id: product.id,
                            }}
                          >
                            <KTSVG
                              path='media/icons/duotune/arrows/arr001.svg'
                              className='svg-icon-muted svg-icon-1hx'
                            />
                          </Link>
                        </td> */}
                      </tr>
                    ))
                  )}
                </tbody>
                {/*end::Table body*/}
              </table>
            </div>
            {/*end::Table*/}
          </div>
          {/*end: Card Body*/}
        </div>
        {/*end::Table widget 12*/}
      </>
    </div>
  );
};

export { NewProduct };
