import React from 'react'
import {Skeleton} from 'antd'

interface PriceSkeletonProps {
  count: number
}

const TransactionSkeleton: React.FC<PriceSkeletonProps> = ({count}) => {
  const skeletonItems = Array.from({length: count}, (_, index) => (
    <tr key={index}>
      <td className='text-center'>
        <div className='d-flex justify-content-center'>
          <Skeleton.Button
            active
            size='default'
            shape='default'
            block={true}
            style={{width: '10px'}}
          />
        </div>
      </td>
      <td className='text-center'>
        <Skeleton.Input style={{width: 40, height: 20}} active />
      </td>
      <td className='text-center'>
        <Skeleton.Input style={{width: 40, height: 20}} active />
      </td>
      <td className='text-center'>
        <Skeleton.Input style={{width: 40, height: 20}} active />
      </td>
      <td className='text-center'>
        <Skeleton.Input style={{width: 40, height: 20}} active />
      </td>
      <td className='text-center'>
        <div className='card-toolbar'>
          <Skeleton.Button active size='default' shape='default' style={{width: '100px'}} />
        </div>
      </td>
      <td className='text-center'>
        <div className='d-flex justify-content-center align-items-center'>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            type='button'
          >
            <Skeleton.Avatar active size={20} shape='circle' />
          </button>
        </div>
      </td>
    </tr>
  ))

  return <React.Fragment>{skeletonItems}</React.Fragment>
}

export {TransactionSkeleton}
