import clsx from "clsx";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  Search,
  ThemeModeSwitcher,
} from "../../../partials";
import { useLayout } from "../../core";
import { useAuth } from "../../../../app/modules/auth";
import { useEffect, useState } from "react";
import API from "../../../../utils/apiProvider";
import { Skeleton } from "antd";

const itemClass = "ms-1 ms-lg-3";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "svg-icon-1";

const Navbar = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const { config } = useLayout();
  const [userData, setUserData] = useState<{
    name: string;
    email: string;
    photo: string;
  } | null>(null);
  const token = currentUser?.token;

  useEffect(() => {
    if (token) {
      GetProfile();
    }
  }, [token]);

  const GetProfile = async () => {
    setLoading(true);

    try {
      const resProfileData = await API.GetProfile(token);

      if (resProfileData && resProfileData.data) {
        const { name, email, photo } = resProfileData.data;
        setUserData({ name, email, photo });
        setLoading(false);
      } else {
        console.error("Error fetching home data:", resProfileData);
        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred while fetching home data:", error);
      setLoading(false);
    }
  };
  return (
    <div className="app-navbar flex-shrink-0">
      {/* <div className={clsx("app-navbar-item align-items-stretch", itemClass)}>
        <Search />
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <div id="kt_activities_toggle" className={btnClass}>
          <KTSVG
            path="/media/icons/duotune/general/gen032.svg"
            className={btnIconClass}
          />
        </div>
      </div> */}

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          className={btnClass}
        >
          <KTSVG
            path="/media/icons/duotune/general/gen007.svg"
            className={btnIconClass}
          />
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx("btn-active-light-primary btn-custom")}
        />
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          {userData?.photo ? (
            <img alt="Profile" src={userData?.photo} />
          ) : (
            <Skeleton.Avatar active size={40} shape="circle" />
          )}
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/text/txt001.svg"
              className={btnIconClass}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
