// GlobalButton.jsx
import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  onClick: () => void
  iconPath?: string
  buttonText: string | JSX.Element
}

const Button: React.FC<ButtonProps> = ({onClick, iconPath, buttonText}) => {
  return (
    <button type='button' className='btn btn-sm btn-light-primary me-4' onClick={onClick}>
      {iconPath && <KTSVG path={iconPath} className='svg-icon-2 me-2' />}
      {buttonText}
    </button>
  )
}

export default Button
