import React from 'react'

interface ShopDescriptionProps {
  description: string | null // Update type to allow null
}

const truncateDescription = (description: string | null) => {
  if (!description) return '' // Add null check
  const words = description.split(' ')
  if (words.length <= 8) {
    return description
  }
  return words.slice(0, 12).join(' ') + '...'
}

const FormatDesc: React.FC<ShopDescriptionProps> = ({description}) => {
  const truncatedDescription = truncateDescription(description)

  return <span className='text-muted fw-bold mb-1 fs-6'>{truncatedDescription}</span>
}

export {FormatDesc}
