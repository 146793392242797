import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { PageTitle } from "../../../../_metronic/layout/core";
import { useIntl } from "react-intl";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { AddSubCategoryModal } from "./modal/AddSubcategoryModal";
import { Loading } from "../../global/Loading";
import { UpdateSubCategoryModal } from "./modal/UpdateSubCategoryModal";
import { MarketplaceSkeleton } from "../skeleton/MarketPlaceSkeleton";
import { DeleteModal } from "../../global/DeleteModal";
import { Pagination } from "../../global/Pagination";
import { AlertMessage } from "../../global/AlertMessage";
import { Empty, Spin } from "antd";
import SearchInput from "../../global/SearchInput";

interface SubCategory {
  id: number;
  name: string;
  fee: number;
  is_active: boolean;
  child_count: string;
  childs: any[];
}
type MetaData = {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
};

const SubCategory: React.FC = () => {
  const level = "sub";
  const location = useLocation();
  const { currentUser } = useAuth();
  const [subcategoryData, setSubCategoryData] = useState<SubCategory[]>([]);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<
    number | null
  >(null);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const token = currentUser?.token;
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [zeroData, setZeroData] = useState("");
  const [meta, setMetaData] = useState<MetaData>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const { name, type, parent_id } = (location.state as {
    subCategories: SubCategory[];
    type: string;
    parent_id: number;
    name: string;
  }) || { subCategories: [], name: "", type: "", id: 0 };

  const GetSubCategory = async () => {
    setLoading(true);
    const resDataCategory = await API.GetCategory(
      token,
      searchQuery,
      level,
      parent_id,
      meta.page,
      type,
      meta.per_page
    );
    if (resDataCategory && resDataCategory.data.length > 0) {
      setSubCategoryData(resDataCategory.data);
      setMetaData(resDataCategory.meta);
    } else {
      setSubCategoryData([]);
      setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
      setZeroData(resDataCategory.data.message);
    }
    setLoading(false);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  useEffect(() => {
    const newIndex = (meta.page - 1) * meta.per_page;
    setStartIndex(newIndex);
  }, [meta.page, meta.per_page]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateOpenModal = () => {
    setIsUpdateModalOpen(true);
  };

  const handleUpdateCloseModal = () => {
    setIsUpdateModalOpen(false);
  };

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    if (token) {
      GetSubCategory();
    }
  }, [token, meta.page, searchQuery, meta.per_page]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlert]);

  const handleAddSubCategory = async (formData: any) => {
    setLoading(true);

    const { name, fee } = formData;

    const formDataAPI = new FormData();

    formDataAPI.append("parent_id", parent_id.toString());
    formDataAPI.append("level", level);
    formDataAPI.append("type", type);
    formDataAPI.append("name", name);
    formDataAPI.append("fee", fee);

    const resAddCategory = await API.AddCategory(formDataAPI, token);

    if (resAddCategory) {
      await GetSubCategory();
      setShowAlert(true);
      setSuccessMessage(resAddCategory.message);
      setLoading(false);
    } else {
      setShowAlertError(true);
      setErrorMessage(resAddCategory.data.message);
    }
  };

  const handleUpateSubCategory = async (formData: any, id: number) => {
    setLoading(true);

    const { name, fee } = formData;

    const formDataAPI = new FormData();
    formDataAPI.append("parent_id", parent_id.toString());
    formDataAPI.append("level", level);
    formDataAPI.append("type", type);
    formDataAPI.append("name", name);
    formDataAPI.append("fee", fee);

    const resUpdateCategory = await API.UpdateCategory(formDataAPI, token, id);

    if (resUpdateCategory) {
      await GetSubCategory();
      setShowAlert(true);
      setSuccessMessage(resUpdateCategory.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resUpdateCategory.data.message);
    }
    setLoading(false);
  };

  const handleDeleteSubCategory = async (id: number) => {
    const resDeleteSubCategory = await API.DeleteCategory(token, id);
    if (resDeleteSubCategory) {
      await GetSubCategory();
      setShowAlert(true);
      setSuccessMessage(resDeleteSubCategory.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resDeleteSubCategory.data.message);
    }
  };

  const handleToggleActive = async (id: number) => {
    const resUpdateStatusUser = await API.UpdateStatusCategory(id, token);
    if (resUpdateStatusUser) {
      setSubCategoryData((prevSubCategoryData) => {
        return prevSubCategoryData.map((category) => {
          if (category.id === id) {
            return { ...category, is_active: !category.is_active };
          }
          return category;
        });
      });
      setShowAlert(true);
      setSuccessMessage(resUpdateStatusUser.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resUpdateStatusUser.data.message);
    }
  };

  return (
    <>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}

      <div className="card mb-xl-10">
        <div className="card mb-xl-8">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">List Sub Kategori ({name})</h3>
            </div>
          </div>
          <div className="separator separator mb-4"></div>

          <div className="card-header border-0">
            <div className="card-toolbar">
              <button
                type="button"
                className="btn btn-sm btn-light-primary me-4"
                onClick={() => window.history.back()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr002.svg"
                  className="svg-icon-2"
                />
              </button>
              <button
                type="button"
                className="btn btn-sm btn-light-primary"
                onClick={handleOpenModal}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                Tambah Sub Kategori
              </button>

              {isModalOpen && (
                <AddSubCategoryModal
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  handleAddSubCategory={handleAddSubCategory}
                />
              )}

              {isUpdateModalOpen && (
                <UpdateSubCategoryModal
                  isOpen={isUpdateModalOpen}
                  onClose={handleUpdateCloseModal}
                  handleUpateSubCategory={handleUpateSubCategory}
                  id={selectedSubCategoryId}
                  category={subcategoryData.find(
                    (category) => category.id === selectedSubCategoryId
                  )}
                />
              )}
            </div>
            <div className="d-flex align-items-center position-relative">
              <SearchInput placeholder="Search" onSearch={handleSearch} />
            </div>
          </div>
          <div className="card-body py-3">
            <div className="table-responsive">
              <table
                id="kt_table_users"
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              >
                <thead>
                  <tr className="text-muted fw-bolder fs-7 text-uppercase bg-light text-center">
                    <th className="min-w-60px rounded-start">No</th>

                    <th className="min-w-125px">Nama</th>
                    <th className="min-w-125px">Induk</th>
                    <th className="min-w-125px">Sub-Sub Kategori</th>
                    <th className="min-w-125px">Status</th>
                    <th className="pe-4 min-w-20px rounded-end">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan={8}
                        className="text-center text-muted fw-bold fs-6"
                      >
                        <Spin size="default" />
                      </td>
                    </tr>
                  ) : subcategoryData.length > 0 ? (
                    subcategoryData.map((subCategory, index) => (
                      <tr key={subCategory.id}>
                        <td className="text-center">
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center flex-column">
                              <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                                {startIndex + index + 1}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="d-flex justify-content-start flex-column">
                            <span className="text-dark fw-bold fs-6">
                              {subCategory.name}
                            </span>
                          </div>
                        </td>
                        <td className="text-center">
                          <span className="text-muted fw-bold mb-1 fs-6">
                            {subCategory.child_count} Sub-Sub Kategori
                          </span>
                        </td>
                        <td className="text-center">
                          <Link
                            to="/marketplace/sub-sub-kategori"
                            state={{
                              subCategories: subCategory.childs || [],
                              type: type,
                              parent_id: subCategory.id,
                              name: subCategory.name,
                              name1: name,
                            }}
                            className="btn btn-sm btn-light-primary"
                          >
                            <KTSVG
                              path="/media/icons/duotune/general/gen010.svg"
                              className="svg-icon-2"
                            />
                            Sub-Sub Kategori
                          </Link>
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                            <input
                              className="form-check-input h-30px w-60px"
                              checked={subCategory.is_active}
                              onClick={() => handleToggleActive(subCategory.id)}
                              type="checkbox"
                              value=""
                              id={`flexSwitch${subCategory.id}`}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <button
                            onClick={() => {
                              setSelectedSubCategoryId(subCategory.id);
                              handleUpdateOpenModal();
                            }}
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-2"
                          >
                            <KTSVG
                              path="/media/icons/duotune/art/art005.svg"
                              className="svg-icon-3"
                            />
                          </button>
                          <button
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target={`#kt_modal_${subCategory.id}`}
                          >
                            <KTSVG
                              path="/media/icons/duotune/general/gen027.svg"
                              className="svg-icon-3"
                            />
                          </button>
                          <DeleteModal
                            id={subCategory.id}
                            name={`Kategori ${name} -> ${subCategory.name}`}
                            onDelete={handleDeleteSubCategory}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={8}
                        className="text-center text-muted fw-bold fs-6"
                      >
                        {zeroData ? (
                          <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{ height: 60 }}
                            description={
                              <span className="text-muted fw-bold">
                                {zeroData}
                              </span>
                            }
                          />
                        ) : null}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                totalRecords={meta.total}
                recordsPerPage={meta.per_page}
                currentPage={meta.page}
                onPageChange={handlePageChange}
                onRecordsPerPageChange={handleRecordsPerPageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { SubCategory };
