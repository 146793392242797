/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {TransactionComponent} from '../../modules/marketplace/transaction/TransactionComponent'
import {ListsWidget3, TablesWidget10} from '../../../_metronic/partials/widgets'
import usePageTitle from '../../modules/global/PageTitle'

const TransaksiPage: FC = () => (
  <>
    <div className='card mb-xl-10'>
      <TransactionComponent className='mb-xl-8' />
    </div>
  </>
)

const TransaksiWrapper: FC = () => {
  const intl = useIntl()
  usePageTitle('Transaksi')

  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <TransaksiPage />
    </>
  )
}

export {TransaksiWrapper}
