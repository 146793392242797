/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import usePageTitle from "../../modules/global/PageTitle";
import { LogPage } from "../../modules/log/LogPage";

const AkunPage: FC = () => (
  <>
    <LogPage />
  </>
);

const LogWrapper: FC = () => {
  const intl = useIntl();
  usePageTitle("Log");

  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <AkunPage />
    </>
  );
};

export { LogWrapper };
