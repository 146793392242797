import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import Select from 'react-select'
import {FC} from 'react'

interface UpdateKategoriModalProps {
  isOpen: boolean
  onClose: () => void
  handleupdateStatusWhitdraw: (formData: any, id: number) => void
  id: number | null
  whitdraw?: {
    id: number
    status: string
  } | null
}

const UpdateStatusWhitdrawModal: FC<UpdateKategoriModalProps> = ({
  isOpen,
  onClose,
  handleupdateStatusWhitdraw,
  id,
  whitdraw,
}) => {
  const [formData, setFormData] = useState({
    status: whitdraw?.status || '',
    reason: '',
  })
  const [isReasonValid, setIsReasonValid] = useState(true)

  useEffect(() => {
    setFormData({
      status: whitdraw?.status || '',
      reason: '',
    })
  }, [whitdraw])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})

    if (name === 'reason') {
      setIsReasonValid(value.trim() !== '')
    }
  }
  const handleClose = () => {
    setFormData({
      status: '',
      reason: '',
    })
    onClose()
  }

  return (
    <>
      {isOpen && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>Edit Status</h2>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    aria-label='Close'
                    onClick={handleClose}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </button>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <form id='kt_modal_add_user_form' className='form' noValidate>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Status</label>
                      <select
                        className='form-select form-select-solid'
                        aria-label='Select example'
                        value={formData.status}
                        onChange={(e) => setFormData({...formData, status: e.target.value})}
                      >
                        <option value=''>Pilih status</option>
                        <option value='approved' selected={formData.status === 'approved'}>
                          Terima
                        </option>
                        <option value='rejected' selected={formData.status === 'rejected'}>
                          Tolak
                        </option>
                      </select>
                    </div>
                    {formData.status === 'rejected' && (
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Alasan Penolakan</label>
                        <input
                          placeholder='Masukkan alasan penolakan'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0', {
                            'is-invalid': !isReasonValid,
                          })}
                          type='text'
                          name='reason'
                          value={formData.reason}
                          onChange={handleChange}
                          autoComplete='off'
                        />
                        {!isReasonValid && (
                          <div className='invalid-feedback'>
                            Alasan penolakan tidak boleh kosong
                          </div>
                        )}
                      </div>
                    )}
                  </form>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' onClick={handleClose}>
                    Tutup
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary'
                    disabled={formData.status === 'rejected' && !formData.reason.trim()}
                    onClick={async () => {
                      if (id !== null) {
                        await handleupdateStatusWhitdraw(formData, id)
                        onClose()
                      }
                    }}
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {UpdateStatusWhitdrawModal}
