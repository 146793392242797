import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'
import API from '../../../../utils/apiProvider'
import {Link, useLocation} from 'react-router-dom'
import {ChatInner} from './ChatInner'
import clsx from 'clsx'
import {Empty} from 'antd'

type ShopMessage = {
  id: number
  photo: string
  name: string
  message: string
  count: number
  date: string
  online: boolean
  last_online: string
}

const ListChat: FC<{role: string}> = ({role}) => {
  const {currentUser} = useAuth()
  const token = currentUser?.token
  const [loading, setLoading] = useState(false)
  const [categoryData, setCategoryData] = useState<ShopMessage[]>([])
  const [selectedMessage, setSelectedMessage] = useState<ShopMessage | null>(null)
  const [selectedMessageId, setSelectedMessageId] = useState<number | null>(null)
  const [zeroData, setZeroData] = useState('')

  useEffect(() => {
    handleRoleChange(role)
  }, [role])

  const handleRoleChange = async (newRole: string) => {
    setLoading(true)
    setSelectedMessage(null)
    setSelectedMessageId(null)
    const resDataCategory = await API.GetChat(token, newRole)

    if (resDataCategory && resDataCategory.data.length > 0) {
      setCategoryData(resDataCategory.data)
    } else {
      setZeroData(resDataCategory.data.message)
      setCategoryData([])
    }
    setLoading(false)
  }

  const handleItemClick = (id: number) => {
    const selectedMessage = categoryData.find((item) => item.id === id) || null
    setSelectedMessage(selectedMessage)
    setSelectedMessageId(id)
  }

  return (
    <div className='d-flex flex-column flex-lg-row'>
      <div className='flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-400px mb-10 mb-lg-0'>
        <div className='card card-flush'>
          <div className='card-header pt-7' id='kt_chat_contacts_header'>
            <form className='w-100 position-relative' autoComplete='off'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
              />
              <input
                type='text'
                className='form-control form-control-solid px-15'
                name='search'
                placeholder='Search'
              />
            </form>
          </div>

          <div className='card-body pt-5' id='kt_chat_contacts_body'>
            <div
              className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
              data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
              data-kt-scroll-offset='0px'
            >
              {categoryData.length === 0 ? (
                <Empty
                  image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                  imageStyle={{height: 60}}
                  description={<span className='text-muted fw-bold'>{zeroData}</span>}
                />
              ) : (
                categoryData.map((item) => (
                  <div
                    key={item.id}
                    className={`d-flex flex-stack py-4 p-4 rounded ${
                      selectedMessageId === item.id ? 'active bg-light' : ''
                    }`}
                    onClick={() => handleItemClick(item.id)}
                    style={{cursor: 'pointer'}}
                    onMouseEnter={(e) => e.currentTarget.classList.add('bg-light')}
                    onMouseLeave={(e) => {
                      if (selectedMessageId !== item.id) {
                        e.currentTarget.classList.remove('bg-light')
                      }
                    }}
                  >
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px symbol-circle'>
                        <img src={item.photo} alt={item.name} />
                      </div>

                      <div className='ms-5'>
                        <span className='fs-5 fw-bolder text-gray-900 mb-2'>{item.name}</span>
                        <div className='fw-bold text-gray-400'>{item.message}</div>
                      </div>
                    </div>

                    <div className='d-flex flex-column align-items-end ms-2'>
                      <span className='text-muted fs-7 mb-1'>{item.last_online}</span>
                      {item.count > 0 && selectedMessageId !== item.id && (
                        <span className='badge badge-sm badge-circle badge-light-success'>
                          {item.count}
                        </span>
                      )}
                    </div>
                  </div>
                ))
              )}

              <div className='separator separator-dashed d-none'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
        {!selectedMessage && (
          <div className='card' id='kt_chat_messenger'>
            <div className='card-body p-0'>
              <div className='card-px text-center py-20 my-10'>
                <h2 className='fs-2x fw-bold mb-10'>Belum Ada Obrolan Aktif</h2>
                <p className='text-gray-500 fs-4 fw-semibold mb-4'>
                  Anda belum memulai obrolan dengan siapapun.
                  <br />
                  Mulailah dengan memilih kontak disamping untuk memulai obrolan.
                </p>
              </div>
              <div className='text-center px-4 my-10'>
                <Empty
                  image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                  imageStyle={{height: 400}}
                  description={<span className='text-muted fw-bold'>{zeroData}</span>}
                />
              </div>
            </div>
          </div>
        )}
        <div className='card' id='kt_chat_messenger'>
          {selectedMessage && (
            <ChatInner
              name={selectedMessage.name}
              photo={selectedMessage.photo}
              messageId={selectedMessageId}
              key={selectedMessageId}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export {ListChat}
