import React, { useState, useEffect } from "react";
import { useAuth } from "../../../auth";
import API from "../../../../../utils/apiProvider";
import { useLocation } from "react-router-dom";
import { PriceListExpedition } from "./PriceListExpedition";
import usePageTitle from "../../../global/PageTitle";

type PriceExpedition = {
  id: number;
  label: string;
};
const PriceExpedition: React.FC = () => {
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<PriceExpedition[]>([]);

  const [meta, setMetaData] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [dataFetched, setDataFetched] = useState(false);
  const location = useLocation();
  const { name } = (location.state as { name: string }) || { name: "" };
  const { code } = (location.state as { code: string }) || { code: "" };
  const { id } = (location.state as { id: number }) || { id: 0 };
  usePageTitle("Pengguna");

  useEffect(() => {
    const fetchTransactionData = async () => {
      setLoading(true);

      const { id } = (location.state as { id: number }) || { id: 0 };

      if (id !== 0) {
        const resPriceExp = await API.GetPriceExpedition(token, id);

        if (resPriceExp.data && resPriceExp.data.length > 0) {
          const sortedData = resPriceExp.data.sort(
            (a: PriceExpedition, b: PriceExpedition) => a.id - b.id
          );
          setMetaData(resPriceExp.meta);
          setUserData(sortedData);
        } else {
          setUserData([]);
          console.error("Error fetching transaction data:", resPriceExp);
        }
        setDataFetched(true);
      } else {
        console.error("No transaction ID found in location state");
      }

      setLoading(false);
    };

    fetchTransactionData();
  }, [location.state, token]);

  return (
    <div>
      <div className="card mb-xl-10">
        <PriceListExpedition
          className="mb-xl-8"
          id={id}
          name={name}
          code={code}
        />
      </div>
    </div>
  );
};

export default PriceExpedition;
