import React, {useEffect} from 'react'
import {FC} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import RupiahFormat from '../../../../global/Formatrupiah'

type Transaction = {
  id: number
  code: string
  shop: {
    id: number
    photo: string
    name: string
  }
  product: Array<{
    id: number
    photo: string
    name: string
    variant: string | null
    qty: number
    price: number
  }>
  shipping: {
    name: string
    phone: number
    courier: string
    service: string
    resi: string | null
    address: string
  }
  payment: {
    bank: string
    type: string
    number: string
    url: string
    expired: string
  }
  shipping_cost: number
  extra_cost: number
  subtotal: number
  fee: number
  grand_total: number
  status: string
  date: string
}

interface DetailTransactionUserProps {
  isOpen: boolean
  onClose: () => void
  id: number | null
  data: Transaction
}

const DetailTransactionUser: FC<DetailTransactionUserProps> = ({isOpen, onClose, id, data}) => {
  useEffect(() => {
    document.body.classList.toggle('modal-open', isOpen)
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])

  const handleClose = () => {
    onClose()
  }

  return (
    <>
      {isOpen && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content p-4 pt-0 pb-0' style={{borderRadius: '30px'}}>
                <div className='modal-header'>
                  <h2 className='fw-bold'>Detail Pesanan</h2>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    aria-label='Close'
                    onClick={handleClose}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </button>
                </div>
                <div className='modal-body'>
                  <div className='card border-0 mb-6'>
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0'>
                        <div
                          className='bg-light rounded-circle d-flex align-items-center justify-content-center'
                          style={{width: '50px', height: '50px'}}
                        >
                          <KTSVG
                            path='/media/icons/duotune/ecommerce/ecm006.svg'
                            className='svg-icon-2hx svg-icon-primary'
                          />
                        </div>
                      </div>
                      <div className='flex-grow-1 ms-3'>
                        <div>
                          <strong>
                            {data.code} <span className='text-primary'></span>
                          </strong>
                        </div>
                        <div className='text-muted'>
                          <span>{data.date}</span>
                        </div>
                      </div>
                      <div className='text-end'>
                        <div className='fs-5'>
                          <div>
                            <span
                              className={`float-end badge ${
                                data.status === 'pending'
                                  ? 'badge-light-primary'
                                  : data.status === 'nego'
                                  ? 'badge-light-warning'
                                  : data.status === 'paid'
                                  ? 'badge-light-info'
                                  : data.status === 'process'
                                  ? 'badge-light-secondary'
                                  : data.status === 'sent'
                                  ? 'badge-light-success'
                                  : data.status === 'success'
                                  ? 'badge-light-success'
                                  : 'badge-light-danger'
                              }`}
                            >
                              {data.status === 'pending'
                                ? 'Pending'
                                : data.status === 'nego'
                                ? 'Negosiasi'
                                : data.status === 'paid'
                                ? 'Diproses'
                                : data.status === 'process'
                                ? 'Dikemas'
                                : data.status === 'sent'
                                ? 'Dikirim'
                                : data.status === 'success'
                                ? 'Selesai'
                                : 'Pembatalan'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='separator separator-dashed mt-4 mb-4'></div>
                  <div className='mb-4 d-flex align-items-center justify-content-between'>
                    <span className='fw-bold fs-4 me-3'>Detail Produk</span>
                    <div className='float-end'>
                      <img
                        src={data.shop.photo}
                        alt='Foto Toko'
                        style={{
                          width: '38px',
                          height: '38px',
                          borderRadius: '50%',
                          border: '1px solid gray',
                          marginRight: '6px',
                        }}
                      />
                      <span className='fw-bold'>{data.shop.name}</span>
                    </div>
                  </div>

                  <div className='mb-3 d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={data.product[0].photo}
                        alt='Foto Produk'
                        style={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '10%',
                        }}
                        className='me-3'
                      />
                      <div className='d-flex flex-column'>
                        <span className='me-3'>{data.product[0].name}</span>
                        <span className='fw-bold me-3'>
                          {data.product[0].qty} x <RupiahFormat value={data.product[0].price} />
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* Pengiriman */}
                  <div className='separator separator-dashed mt-6 mb-6'></div>
                  <div className='mb-4 d-flex align-items-center justify-content-between'>
                    <span className='fw-bold fs-4 me-3'>Detail Pengiriman</span>
                  </div>

                  <div className='mb-3'>
                    <span className='text-gray-400 fw-semibold'>Pengiriman</span>
                    <span className='float-end'>
                      {data.shipping.courier} - {data.shipping.service}
                    </span>
                  </div>
                  <div className='mb-3'>
                    <span className='text-gray-400 fw-semibold'>Resi</span>
                    <span className='float-end'>
                      {data.shipping.resi ? `${data.shipping.resi}` : 'Resi belum tersedia!'}
                    </span>
                  </div>
                  <div className='mb-3'>
                    <span className='text-gray-400 fw-semibold'>Alamat Pengiriman</span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <div
                      className='bg-light rounded-circle d-flex align-items-center justify-content-center'
                      style={{width: '50px', height: '50px'}}
                    >
                      <KTSVG
                        path='/media/icons/duotune/maps/map008.svg'
                        className='svg-icon-2hx svg-icon-danger'
                      />
                    </div>
                    <div className='flex-grow-1 ms-3'>
                      <div>
                        <strong>
                          {data.shipping.name} <span className='text-primary'></span>
                        </strong>
                      </div>
                      <div className='text-muted'>{data.shipping.phone}</div>
                      <div className='text-muted'>{data.shipping.address}</div>
                    </div>
                  </div>
                  {/* End Pengiriman */}
                  <div className='separator separator-dashed mt-4 mb-4'></div>
                  {/* Detail Pembayaran */}
                  <div className='mb-4 d-flex align-items-center justify-content-between'>
                    <span className='fw-bold fs-4 me-3'>Detail Pembayaran</span>
                  </div>
                  <div className='mb-3'>
                    <span className='text-gray-400 fw-semibold'>Metode Pembayaran</span>
                    <span className='float-end'>
                      {data.payment.bank}-{data.payment.type}
                    </span>
                  </div>
                  <div className='mb-3'>
                    <span className='text-gray-400 fw-semibold'>Status Pembayaran</span>
                    <span className='float-end'>
                      <a href={data.payment.url} target='_blank' rel='noopener noreferrer'>
                        Klik disini
                      </a>
                    </span>
                  </div>
                  <div className='mb-3'>
                    <span className='text-gray-400 fw-semibold'>Biaya Pengiriman</span>
                    <span className='float-end'>
                      <RupiahFormat value={data.shipping_cost} />
                    </span>
                  </div>
                  <div className='mb-3'>
                    <span className='text-gray-400 fw-semibold'>Biaya Penanganan</span>
                    <span className='float-end'>
                      <RupiahFormat value={data.fee} />
                    </span>
                  </div>
                  <div className='mb-3'>
                    <span className='text-gray-400 fw-semibold'>Biaya Tambahan</span>
                    <span className='float-end'>
                      <RupiahFormat value={data.extra_cost} />
                    </span>
                  </div>
                  <div className='mb-3'>
                    <span className='text-gray-400 fw-semibold'>Sub Total</span>
                    <span className='float-end'>
                      <RupiahFormat value={data.subtotal} />
                    </span>
                  </div>
                  <div className='mb-3'>
                    <span className='text-gray-400 fw-semibold'>Total</span>
                    <span className='float-end'>
                      <RupiahFormat value={data.grand_total} />
                    </span>
                  </div>
                </div>

                {/* End Detail Pembayaran */}
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' onClick={handleClose}>
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {DetailTransactionUser}
