import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { KTSVG } from "../../../../_metronic/helpers";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { Loading } from "../../global/Loading";
import { AlertMessage } from "../../global/AlertMessage";
import { Skeleton } from "antd";

const SendNotificationValidationSchema = Yup.object().shape({
  user: Yup.string().oneOf(
    ["all"],
    'Harus memilih "Semua User" atau pilih salah satu pengguna lainnya'
  ),

  title: Yup.string().required("Judul wajib diisi"),

  body: Yup.string().required("Isi pesan wajib diisi"),
});

type NotificationData = {
  id: number;
  name: string;
};

const NotificationComponent: React.FC = () => {
  const [showNotifForm, setshowNotifForm] = useState<boolean>(true);
  const [notificationData, setNotificationData] = useState<NotificationData[]>(
    []
  );
  const [meta, setMetaData] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });

  const [loading, setLoading] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlert]);

  useEffect(() => {
    if (token) {
      getNotification();
    }
  }, [token, meta.page]);

  const getNotification = async () => {
    setLoading(true);
    try {
      const resNotif = await API.GetNotification(token, meta.page, 1000);
      if (resNotif && Array.isArray(resNotif.data)) {
        setNotificationData((prevData) => [...prevData, ...resNotif.data]);
        setMetaData((prevMetaData) => ({
          ...prevMetaData,
          page: prevMetaData.page + 1,
        }));
      } else {
        console.error("Error: Data is not an array", resNotif);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [selectedUser, setSelectedUser] = useState<string>("");
  const [selectedUserName, setSelectedUserName] = useState<string>("");

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;

    if (selectedId === "") {
      setSelectedUser("");
      setSelectedUserName("");
    } else if (selectedId === "all") {
      setSelectedUser("all");
      setSelectedUserName("Semua User");
    } else {
      setSelectedUser(selectedId);
      const user = notificationData.find(
        (item) => item.id === parseInt(selectedId)
      );
      setSelectedUserName(user ? user.name : "");
    }
  };

  const formik = useFormik({
    initialValues: {
      user: "",
      title: "",
      body: "",
    },
    validationSchema: SendNotificationValidationSchema,
    onSubmit: async (values) => {
      setLoadingSubmit(true);
      const formData = new FormData();
      formData.append("type", selectedUser);
      formData.append("title", values.title);
      formData.append("body", values.body);

      const res = await API.SendNotification(formData, token);
      if (res) {
        setSuccessMessage(res.message);
        setShowAlert(true);
        setshowNotifForm(true);
      } else {
        setErrorMessage(res.message);
      }
      setLoadingSubmit(false);
    },
  });

  return (
    <div className="card mb-5 mb-xl-10">
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_signin_method"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Pemberitahuan</h3>
        </div>
      </div>

      <div id="kt_account_signin_method" className="collapse show">
        <div className="card-body border-top p-9">
          <div className="d-flex flex-wrap align-items-center">
            <div
              id="kt_signin_email"
              className={" " + (showNotifForm && "d-none")}
            >
              <div className="fs-6 fw-bolder mb-1">Kirim Pemberitahuan ke</div>
              <div className="fw-bold text-gray-600">
                {selectedUserName || "Semua User"}
              </div>
            </div>

            <div
              id="kt_signin_email_edit"
              className={"flex-row-fluid " + (!showNotifForm && "d-none")}
            >
              <form
                onSubmit={formik.handleSubmit}
                id="kt_signin_change_email"
                className="form"
                noValidate
              >
                <div className="col mb-6">
                  <div className="mb-6">
                    <label className="form-label fs-6 fw-bolder mb-3">
                      Target Pemberitahuan :
                    </label>
                    <div>
                      {loadingSubmit ? (
                        <Skeleton.Input
                          style={{ width: "100%", height: "45px" }}
                          active
                          block
                        />
                      ) : (
                        <select
                          className="form-select form-select-solid"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          defaultValue={selectedUser}
                          onChange={handleSelectChange}
                        >
                          <option value="" disabled>
                            Pilih pengguna
                          </option>
                          <option value="buyer">Buyer</option>
                          <option value="seller">Seller</option>
                        </select>
                      )}
                    </div>
                  </div>

                  <div className="col mb-6">
                    <div className="fv-row mb-0">
                      <label
                        htmlFor="emailaddress"
                        className="form-label fs-6 fw-bolder mb-3"
                      >
                        Judul Pemberitahuan :
                      </label>
                      {loadingSubmit ? (
                        <Skeleton.Input
                          style={{ width: "100%", height: "45px" }}
                          active
                          block
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          id="title"
                          placeholder="Masukkan judul pemberitahuan.."
                          {...formik.getFieldProps("title")}
                        />
                      )}

                      {formik.touched.title && formik.errors.title && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.title}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="fv-row mb-0">
                      <label
                        htmlFor="confirmemailpassword"
                        className="form-label fs-6 fw-bolder mb-3"
                      >
                        Teks Pemberitahuan :
                      </label>
                      {loadingSubmit ? (
                        <Skeleton.Input
                          style={{ width: "100%", height: "45px" }}
                          active
                          block
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Masukkan teks pemberitahuan.."
                          id="confirmemailpassword"
                          {...formik.getFieldProps("body")}
                        />
                      )}
                      {formik.touched.body && formik.errors.body && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.body}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    id="kt_signin_submit"
                    type="submit"
                    className="btn btn-primary  me-2 px-6"
                  >
                    {!loadingSubmit && "Kirim Pemberitahuan"}
                    {loadingSubmit && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Sabar boss...{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>

            <div
              id="kt_signin_email_button"
              className={"ms-auto " + (showNotifForm && "d-none")}
            >
              <button className="btn btn-light btn-active-light-primary">
                Kirim Pemberitahuan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { NotificationComponent };
