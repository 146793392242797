import React, { useState, useEffect } from "react";
import { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { EditVersion } from "../../modules/settings/Version/EditVersion";
import usePageTitle from "../../modules/global/PageTitle";
import { Modal, Button, Form, Alert } from "react-bootstrap";

const correctPassword = "8484";

const VersionWrapper: FC = () => {
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const intl = useIntl();
  usePageTitle("Versi");

  useEffect(() => {
    if (isPasswordModalVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isPasswordModalVisible]);

  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
      setIsPasswordModalVisible(false);
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setPassword("");
      }, 2000);
    }
  };

  return (
    <>
      <PageTitle breadcrumbs={[]} />
      {isAuthenticated ? <EditVersion /> : null}

      <Modal
        show={isPasswordModalVisible}
        onHide={() => (window.location.href = "/")}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Masukkan Kode Akses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAlert && (
            <Alert variant="danger">Kode akses salah. Silakan coba lagi.</Alert>
          )}
          <Form>
            <Form.Group className="mb-3">
              <Form.Control
                type="password"
                placeholder="Masukkan Kode Akses"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => (window.location.href = "/")}
          >
            Batal
          </Button>
          <Button variant="primary" onClick={handlePasswordSubmit}>
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { VersionWrapper };
