import React, {useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {AlertMessage} from '../../../global/AlertMessage'

interface AddBannerModalProps {
  isOpen: boolean
  onClose: () => void
  handleAddBanner: (formData: any) => void
}

const AddBannerModal: React.FC<AddBannerModalProps> = ({isOpen, onClose, handleAddBanner}) => {
  const [formData, setFormData] = useState({
    photo: null as File | null,
    photourl: '',
    url: '',
  })

  const [showAlertError, setShowAlertError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false)
      }, 4000)
    }
    return () => clearTimeout(timeout)
  }, [showAlertError, setShowAlertError])

  useEffect(() => {
    document.body.classList.toggle('modal-open', isOpen)
  }, [isOpen])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(e.target.files)
  }

  const handleFileUpload = (files: FileList | null) => {
    if (files && files[0]) {
      const file = files[0]
      if (file.size > 2 * 1024 * 1024) {
        setShowAlertError(true)
        setErrorMessage('Ukuran file tidak boleh lebih dari 2MB.')
        return
      }
      const fileURL = URL.createObjectURL(file)
      setFormData((prevFormData) => ({
        ...prevFormData,
        photo: file,
        photourl: fileURL,
      }))
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files && files.length > 0) {
      const file = files[0]
      const fileType = file.type.split('/')[1]
      if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
        handleFileUpload(files)
      } else {
        setShowAlertError(true)
        setErrorMessage('Tipe file yang diizinkan adalah jpg, jpeg, dan png.')
      }
    }
  }

  const handleClose = () => {
    setFormData({
      photo: null,
      photourl: '',
      url: '',
    })
    onClose()
  }

  return isOpen ? (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {showAlertError && errorMessage && <AlertMessage type='error' message={errorMessage} />}

        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Tambah Banner</h2>
              <button
                type='button'
                className='btn btn-icon btn-sm btn-active-icon-primary'
                aria-label='Close'
                onClick={handleClose}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </button>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form id='kt_modal_add_user_form' className='form' noValidate>
                <div
                  className='fv-row mb-7'
                  id='drop-zone'
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <label className='d-block fw-bold fs-6 mb-2 required'>Gambar</label>
                  {formData.photourl ? (
                    <div className='position-relative'>
                      <img src={formData.photourl} alt='Preview' className='img-fluid rounded' />
                      <label
                        className='btn btn-icon btn-circle btn-light-primary position-absolute top-0 start-100 translate-middle'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        title='Change avatar'
                      >
                        <i className='bi bi-pencil-fill fs-7'></i>
                        <input
                          type='file'
                          name='photo'
                          accept='.png, .jpg, .jpeg'
                          onChange={handleIconChange}
                          className='position-absolute top-0 start-0 opacity-0 w-100 h-100'
                        />
                      </label>
                    </div>
                  ) : (
                    <label className='form-control form-control-solid btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary position-relative overflow-hidden'>
                      <>
                        <input
                          type='file'
                          name='photo'
                          className='d-none'
                          onChange={handleIconChange}
                          accept='image/png, image/jpeg, image/jpg'
                        />
                        <KTSVG
                          path='/media/icons/duotune/files/fil022.svg'
                          className='svg-icon-2tx svg-icon-primary me-4'
                        />
                        <div className='flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                          <div className='mb-3 mb-md-0'>
                            <h4 className='s-6 text-primary'>Pilih file untuk diunggah</h4>
                            <div className='fs-6 text-primary'>
                              Tipe file yang diizinkan: png, jpg, jpeg.
                            </div>
                          </div>
                        </div>
                      </>
                    </label>
                  )}
                </div>

                <div className='flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                  <div className='mb-3 mb-md-0'>
                    {formData.photourl && (
                      <button
                        type='button'
                        className='btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger mb-4'
                        onClick={() =>
                          setFormData((prevFormData) => ({...prevFormData, photourl: ''}))
                        }
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr011.svg'
                          className='svg-icon-2'
                        />
                        Hapus Gambar
                      </button>
                    )}
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className=' fw-bold fs-6 mb-2'>URL</label>
                  <input
                    placeholder='Masukkan url banner'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    type='text'
                    name='url'
                    value={formData.url}
                    onChange={handleChange}
                    autoComplete='off'
                  />
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' onClick={handleClose}>
                Tutup
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={async () => {
                  await handleAddBanner(formData)
                  onClose()
                }}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  ) : null
}

export {AddBannerModal}
