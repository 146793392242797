import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {FC} from 'react'

interface UpdateKategoriModalProps {
  isOpen: boolean
  onClose: () => void
  handleUpdateBanner: (formData: {photo: File | null; url: string}, id: number) => void
  id: number | null
  banner?: {
    photo: string
    url: string
  } | null
}

const UpdateBannerModal: FC<UpdateKategoriModalProps> = ({
  isOpen,
  onClose,
  handleUpdateBanner,
  id,
  banner,
}) => {
  const [formData, setFormData] = useState({
    photo: banner?.photo ? new File([], banner.photo) : null,
    photoURL: banner?.photo || '',
    url: banner?.url || '',
  })

  useEffect(() => {
    setFormData({
      photo: null,
      photoURL: banner?.photo || '',
      url: banner?.url || '',
    })
  }, [banner])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const file = files[0]
      setFormData({
        ...formData,
        photo: file,
        photoURL: URL.createObjectURL(file),
      })
    }
  }

  const handleClose = () => {
    setFormData({
      photo: null,
      photoURL: '',
      url: '',
    })
    onClose()
  }

  const [isButtonClicked, setIsButtonClicked] = useState(false)

  const handleClick = async () => {
    if (!isButtonClicked && id !== null) {
      setIsButtonClicked(true)
      await handleUpdateBanner(formData, id)
      onClose()
    }
  }

  return (
    <>
      {isOpen && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>Edit Kategori</h2>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    aria-label='Close'
                    onClick={handleClose}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </button>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <form id='kt_modal_add_user_form' className='form' noValidate>
                    <div className='fv-row mb-7' id='drop-zone'>
                      <label className='d-block fw-bold fs-6 mb-2 required'>Gambar</label>
                      {formData.photoURL ? (
                        <div className='position-relative'>
                          <img
                            src={formData.photoURL}
                            alt='Preview'
                            className='img-fluid rounded'
                          />
                          <label
                            className='btn btn-icon btn-circle btn-light-primary position-absolute top-0 start-100 translate-middle'
                            data-kt-image-input-action='change'
                            data-bs-toggle='tooltip'
                            title='Change avatar'
                          >
                            <i className='bi bi-pencil-fill fs-7'></i>
                            <input
                              type='file'
                              name='photo'
                              accept='.png, .jpg, .jpeg'
                              onChange={handlePhotoChange}
                              className='position-absolute top-0 start-0 opacity-0 w-100 h-100'
                            />
                          </label>
                        </div>
                      ) : (
                        <label className='form-control form-control-solid btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary position-relative overflow-hidden'>
                          <>
                            <input
                              type='file'
                              name='photo'
                              className='d-none'
                              onChange={handlePhotoChange}
                              accept='image/png, image/jpeg, image/jpg'
                            />
                            <KTSVG
                              path='/media/icons/duotune/files/fil022.svg'
                              className='svg-icon-2tx svg-icon-primary me-4'
                            />
                            <div className='flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                              <div className='mb-3 mb-md-0'>
                                <h4 className='s-6 text-primary'>Pilih file untuk diunggah</h4>
                                <div className='fs-6 text-primary'>
                                  Tipe file yang diizinkan: png, jpg, jpeg.
                                </div>
                              </div>
                            </div>
                          </>
                        </label>
                      )}
                    </div>

                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>URL</label>
                      <input
                        placeholder='Masukkan URL'
                        className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                        type='text'
                        name='url'
                        value={formData.url}
                        onChange={handleChange}
                        autoComplete='off'
                      />
                    </div>
                  </form>
                </div>

                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' onClick={handleClose}>
                    Tutup
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={handleClick}
                    disabled={isButtonClicked}
                  >
                    {isButtonClicked ? 'Menyimpan...' : 'Simpan'}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {UpdateBannerModal}
