import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import Select from 'react-select'
import {FC} from 'react'

interface UpdateKategoriModalProps {
  isOpen: boolean
  onClose: () => void
  handleUpateSubSubCategory: (formData: any, id: number) => void
  id: number | null
  category?: {
    id: number
    name: string
  } | null
}

const UpdateSubSubCategoryModal: FC<UpdateKategoriModalProps> = ({
  isOpen,
  onClose,
  handleUpateSubSubCategory,
  id,
  category,
}) => {
  const [formData, setFormData] = useState({
    name: category?.name || '',
  })

  useEffect(() => {
    setFormData({
      name: category?.name || '',
    })
  }, [category])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleClose = () => {
    setFormData({
      name: '',
    })
    onClose()
  }

  return (
    <>
      {isOpen && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>Edit Kategori</h2>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    aria-label='Close'
                    onClick={handleClose}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </button>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <form id='kt_modal_add_user_form' className='form' noValidate>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Nama Kategori</label>
                      <input
                        placeholder='Masukkan nama kategori'
                        className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                        type='text'
                        name='name'
                        value={formData.name}
                        onChange={handleChange}
                        autoComplete='off'
                      />
                    </div>
                  </form>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' onClick={handleClose}>
                    Tutup
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={async () => {
                      if (id !== null) {
                        await handleUpateSubSubCategory(formData, id)
                        onClose()
                      }
                    }}
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {UpdateSubSubCategoryModal}
