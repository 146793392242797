import {useEffect} from 'react'

const usePageTitle = (title: string) => {
  useEffect(() => {
    document.title = `${title} - Panel | Baba Market`
    return () => {
      document.title = 'Panel | Baba Market'
    }
  }, [title])
}

export default usePageTitle
