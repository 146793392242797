import React, {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../user-management/users-list/core/QueryRequestProvider'
import {useQueryResponse} from '../../user-management/users-list/core/QueryResponseProvider'

const FilterCategory = ({
  onChange,
  onReset,
}: {
  onChange: (type: string | '') => void
  onReset: () => void
}) => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [type, setType] = useState<string>('')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const handleReset = () => {
    setType('')
    onReset() // Memanggil fungsi onReset saat tombol reset ditekan
  }

  const filterData = () => {
    updateState({
      filter: {type},
      ...initialQueryState,
    })
    onChange(type)
  }

  return (
    <>
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-md me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Kategori</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Tipe:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setType(e.target.value)}
              value={type}
            >
              <option value=''>Pilih tipe kategori</option>
              <option value='material'>Material</option>
              <option value='non-material'>Non-Material</option>
              <option value='ppob'>PPOB</option>
            </select>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={handleReset}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Terapkan
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {FilterCategory}
