import React from "react";
import { KTSVG } from "../../../_metronic/helpers";

type DeleteModalProps = {
  id: number;
  name: string;
  onDelete: (id: number) => void;
};

export const DeleteModal: React.FC<DeleteModalProps> = ({
  id,
  name,
  onDelete,
}) => {
  return (
    <div
      className="modal fade"
      id={`kt_modal_${id}`}
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header d-flex align-items-center justify-content-between">
            <h5 className="modal-title">Hapus {name}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-icon-primary ms-2 text-primary"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>
          <div className="modal-body text-center">
            <p>
              Penghapusan <span className="fw-bold">PERMANEN</span> akan
              dilakukan. Apakah Anda yakin ingin menghapus{" "}
              <span className="fw-bold">{name}</span>?
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Batal
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onDelete(id)}
              data-bs-dismiss="modal"
            >
              Ya, Hapus
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
