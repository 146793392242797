import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import Select, { components } from "react-select";

const colorOptions = [
  { value: "#FFB6C1", label: "Light Pink" },
  { value: "#FF69B4", label: "Hot Pink" },
  { value: "#FFD700", label: "Gold" },
  { value: "#FFA07A", label: "Light Salmon" },
  { value: "#7FFFD4", label: "Aquamarine" },
  { value: "#ADFF2F", label: "Green Yellow" },
  { value: "#FF6347", label: "Tomato" },
  { value: "#FF4500", label: "Orange Red" },
  { value: "#DA70D6", label: "Orchid" },
  { value: "#E6E6FA", label: "Lavender" },
];

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddPrice: (formData: any) => void;
}

const AddPriceExpeditionModal: React.FC<AddUserModalProps> = ({
  isOpen,
  onClose,
  handleAddPrice,
}) => {
  const [formData, setFormData] = useState({
    label: "",
    color: "#000000",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  const handleClose = () => {
    setFormData({
      label: "",
      color: "",
    });
    setIsSubmitting(false);
    onClose();
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    await handleAddPrice(formData);
    setIsSubmitting(false);
    onClose();
  };

  const handleColorChange = (selectedOption: any) => {
    setFormData({
      ...formData,
      color: selectedOption.value,
    });
  };

  const ColourOption = (props: any) => (
    <components.Option {...props}>
      <span
        style={{
          display: "inline-block",
          width: "15px",
          height: "15px",
          borderRadius: "5px",
          backgroundColor: props.data.value,
          marginRight: "10px",
        }}
      />
      {props.data.label}
    </components.Option>
  );

  const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <span
        style={{
          display: "inline-block",
          width: "15px",
          height: "15px",
          borderRadius: "5px",
          backgroundColor: props.data.value,
          marginRight: "10px",
        }}
      />
      {props.data.label}
    </components.SingleValue>
  );

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        aria-hidden={!isOpen}
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Tambah Label</h2>
              <button
                type="button"
                className="btn btn-icon btn-sm btn-active-icon-primary mb-4"
                aria-label="Close"
                onClick={handleClose}
                disabled={isSubmitting}
                data-bs-dismiss="modal"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon-1"
                />
              </button>
            </div>
            <div className="modal-body mx-5 mx-xl-15 ">
              <form id="kt_modal_add_user_form" className="form" noValidate>
                <div
                  className="d-flex flex-column me-n7 pe-7"
                  id="kt_modal_add_user_scroll"
                >
                  <div className="fv-row mb-7">
                    <label className="required fw-bold fs-6 mb-2">
                      Nama Label
                    </label>
                    <input
                      placeholder="Masukkan nama label"
                      type="text"
                      name="label"
                      value={formData.label}
                      onChange={handleChange}
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0"
                      )}
                      autoComplete="off"
                      disabled={isSubmitting}
                    />
                  </div>
                  <label className="required fw-bold fs-6 mb-2">
                    Pilih Warna
                  </label>
                  <div className="fv-row mb-7">
                    <Select
                      value={colorOptions.find(
                        (option) => option.value === formData.color
                      )}
                      className="react-select-styled react-select-solid"
                      classNamePrefix="react-select"
                      placeholder={"Pilih warna"}
                      onChange={handleColorChange}
                      options={colorOptions}
                      components={{ Option: ColourOption, SingleValue }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary me-4"
                onClick={handleClose}
                disabled={isSubmitting}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSave}
                disabled={isSubmitting}
                data-bs-dismiss="modal"
              >
                {isSubmitting ? "Menyimpan..." : "Simpan"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export { AddPriceExpeditionModal };
