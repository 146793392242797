import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const EventModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  handleSave: (title: string, date: string) => void;
  selectedDate?: { start: string; end: string };
}> = ({ show, handleClose, handleSave, selectedDate }) => {
  const [eventTitle, setEventTitle] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    if (selectedDate) {
      setDate(selectedDate.start);
    } else {
      const today = new Date().toISOString().split("T")[0];
      setDate(today);
    }
  }, [selectedDate]);

  const handleSaveClick = () => {
    if (eventTitle && date) {
      handleSave(eventTitle, date);
      setEventTitle("");
      setDate("");
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      class="modal fade"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Tambah Hari Libur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="eventTitle" className="mb-3">
            <Form.Label>Judul Hari Libur</Form.Label>
            <Form.Control
              type="text"
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
              placeholder="Masukkan judul hari libur"
            />
          </Form.Group>
          <Form.Group controlId="eventDate" className="mb-3">
            <Form.Label>Tanggal Hari Libur</Form.Label>
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-flex btn-sm btn-light-danger"
          onClick={handleClose}
        >
          Tutup
        </button>
        <button
          className="btn btn-flex btn-sm btn-danger"
          onClick={handleSaveClick}
        >
          Simpan Hari Libur
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventModal;
