import React from "react";
import { Modal, Button } from "react-bootstrap";

interface EventDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  eventTitle: string;
  eventDate: string;
  onEdit: () => void;
  onDelete: () => void;
}

const EventDetailsModal: React.FC<EventDetailsModalProps> = ({
  show,
  handleClose,
  eventTitle,
  eventDate,
  onEdit,
  onDelete,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      class="modal fade"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className="border-0 justify-content-end">
        <Button
          variant="icon btn-sm btn-color-gray-500 btn-active-icon-primary me-2"
          data-bs-toggle="tooltip"
          title="Edit Event"
          onClick={onEdit}
        >
          <i className="ki-duotone ki-pencil fs-2">
            <span className="path1" />
            <span className="path2" />
          </i>
        </Button>
        <Button
          variant="icon btn-sm btn-color-gray-500 btn-active-icon-danger me-2"
          data-bs-toggle="tooltip"
          title="Delete Event"
          onClick={onDelete}
        >
          <i className="ki-duotone ki-trash fs-2">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
            <span className="path5" />
          </i>
        </Button>
        <Button
          variant="icon btn-sm btn-color-gray-500 btn-active-icon-primary"
          data-bs-toggle="tooltip"
          title="Hide Event"
          onClick={handleClose}
        >
          <i className="ki-duotone ki-cross fs-2x">
            <span className="path1" />
            <span className="path2" />
          </i>
        </Button>
      </Modal.Header>

      <Modal.Body className="pt-0 pb-20 px-lg-17">
        <div className="d-flex">
          <i className="ki-duotone ki-calendar-8 fs-1 text-muted me-5">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
            <span className="path5" />
            <span className="path6" />
          </i>
          <div className="mb-9">
            <div className="d-flex align-items-center mb-2">
              <span className="fs-3 fw-bold me-3" data-kt-calendar="event_name">
                {eventTitle}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mb-2">
          <span className="bullet bullet-dot h-10px w-10px bg-success ms-2 me-7" />
          <div className="fs-6">
            <span className="fw-bold">{eventDate}</span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EventDetailsModal;
