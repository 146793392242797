import React from "react";
import { KTSVG } from "../../../_metronic/helpers";

type DeleteModalProps = {
  modalId: string;
  ids: number[];
  names: string[];
  onDelete: (ids: number[]) => void;
};

export const DeleteModalProduct: React.FC<DeleteModalProps> = ({
  modalId,
  ids,
  names,
  onDelete,
}) => {
  const itemCount = ids.length;

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header d-flex align-items-center justify-content-between">
            {itemCount > 1 ? (
              <h5 className="modal-title">Hapus {itemCount} item berikut?</h5>
            ) : (
              <h5 className="modal-title">Hapus {names[0]}</h5>
            )}
            <div
              className="btn btn-icon btn-sm btn-active-icon-primary ms-2 text-primary"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>
          <div className="modal-body text-center">
            {itemCount > 1 ? (
              <p>
                Anda akan menghapus <span className="fw-bold">{itemCount}</span>{" "}
                item <span className="fw-bold">PERMANEN</span>. Apakah Anda
                yakin ingin melanjutkan?
              </p>
            ) : (
              <p>
                Penghapusan <span className="fw-bold">PERMANEN</span> akan
                dilakukan. Apakah Anda yakin ingin menghapus{" "}
                <span className="fw-bold">{names[0]}</span>?
              </p>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Batal
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onDelete(ids)}
              data-bs-dismiss="modal"
            >
              Ya, Hapus
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
