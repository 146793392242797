import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";
import { KTCardBody, KTSVG } from "../../../../_metronic/helpers";
import { Empty, Image, Spin } from "antd";
import { UpdatePaymentModal } from "./modal/UpdatePaymentModal";

type PaymentData = {
  id: number;
  bank_type: string;
  bank_code: string;
  name: string;
  icon: string;
  fee: number;
  is_active: boolean;
};

const PaymentComponent: React.FC = () => {
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentData, setPaymentData] = useState<PaymentData[]>([]);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [zeroData, setZeroData] = useState("");
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState<number | null>(
    null
  );

  useEffect(() => {
    fetchData();
  }, [token]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlert]);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlertError]);

  const fetchData = async () => {
    setLoading(true);
    const res = await API.GetPayment(token);
    console.log(res, "payment");

    if (res && res.data) {
      setPaymentData(res.data);
    } else {
      setPaymentData([]);
      setZeroData(res.message);
    }
    setLoading(false);
  };

  const handleUpdatePayment = async (formData: any, id: number) => {
    const { bank_type, bank_code, name, icon, fee, is_active } = formData;

    const formDataAPI = new FormData();
    if (icon instanceof File) {
      formDataAPI.append("icon", icon);
    }
    formDataAPI.append("bank_type", bank_type);
    formDataAPI.append("bank_code", bank_code);
    formDataAPI.append("name", name);
    formDataAPI.append("fee", fee);
    formDataAPI.append("is_active", is_active.toString());

    const res = await API.UpdatePayment(formDataAPI, token, id);
    console.log(res, "update");
    for (let [key, value] of formDataAPI.entries()) {
      console.log(`${key}:`, value);
    }

    if (res.message === "Berhasil mengubah data pembayaran") {
      await fetchData();
      setShowAlert(true);
      setSuccessMessage(res.message);
    } else {
      setErrorMessage(res.data.message);
      setShowAlertError(true);
    }
  };

  const handleUpdateStatus = async (id: number, isActive: boolean) => {
    const selectedPayment = paymentData.find((payment) => payment.id === id);
    if (!selectedPayment) return;

    const formDataAPI = new FormData();
    formDataAPI.append("bank_type", selectedPayment.bank_type);
    formDataAPI.append("bank_code", selectedPayment.bank_code);
    formDataAPI.append("name", selectedPayment.name);
    formDataAPI.append("fee", selectedPayment.fee.toString());
    formDataAPI.append("is_active", isActive.toString());

    const res = await API.UpdatePayment(formDataAPI, token, id);
    console.log(res, "update");
    for (let [key, value] of formDataAPI.entries()) {
      console.log(`${key}:`, value);
    }

    if (res) {
      await fetchData();
      setShowAlert(true);
      setSuccessMessage(res.message);
    } else {
      setErrorMessage(res.data.message);
      setShowAlertError(true);
    }
  };

  const handleUpdateOpenModal = () => {
    setIsUpdateModalOpen(true);
  };

  const handleUpdateCloseModal = () => {
    setIsUpdateModalOpen(false);
  };

  const formatToRupiah = (price: number) => {
    const formatted = `Rp ${Number(price).toLocaleString("id-ID")}`;
    return formatted;
  };

  return (
    <div className="card mb-xl-10">
      <div className="card mb-xl-8">
        {showAlertError && errorMessage && (
          <AlertMessage type="error" message={errorMessage} />
        )}

        {showAlert && successMessage && (
          <AlertMessage type="success" message={successMessage} />
        )}
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">List Pembayaran</h3>
          </div>
        </div>
        <div className="separator separator"></div>

        <KTCardBody className="py-4">
          <div className="table-responsive">
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <thead>
                <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                  <th className="min-w-60px rounded-start">No</th>
                  <th className="min-w-125px">Nama</th>
                  <th className="min-w-125px">Tipe Bank</th>
                  <th className="min-w-125px">Kode Bank</th>
                  <th className="min-w-125px">Biaya Penanganan</th>
                  <th className="min-w-125px">Status</th>
                  <th className="pe-4 min-w-125px rounded-end">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      <Spin size="default" />
                    </td>
                  </tr>
                ) : paymentData.length > 0 ? (
                  paymentData.map((payment, index) => (
                    <tr key={payment.id}>
                      <td className="text-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="d-flex justify-content-center flex-column">
                            <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                              {index + 1}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-60px me-5">
                            <span className="symbol-label bg-light">
                              <Image
                                src={payment.icon}
                                className="rounded"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "contain",
                                }}
                              />
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="text-dark fw-bold fs-6 me-2">
                              {payment.name}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="d-flex justify-content-start flex-column">
                            <span className="text-muted fw-bold mb-1 fs-6">
                              {payment.bank_type}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="d-flex justify-content-start flex-column">
                            <span className="text-muted fw-bold mb-1 fs-6">
                              {payment.bank_code}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="d-flex justify-content-start flex-column">
                            <span className="text-muted fw-bold mb-1 fs-6">
                              {formatToRupiah(payment.fee)}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                          <input
                            className="form-check-input h-30px w-60px"
                            checked={payment.is_active}
                            type="checkbox"
                            id={`isActive-${payment.id}`}
                            onChange={() =>
                              handleUpdateStatus(payment.id, !payment.is_active)
                            }
                          />
                        </div>
                      </td>
                      <td className="text-center">
                        <button
                          onClick={() => {
                            setSelectedPaymentId(payment.id);
                            handleUpdateOpenModal();
                          }}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-2"
                        >
                          <KTSVG
                            path="/media/icons/duotune/art/art005.svg"
                            className="svg-icon-3"
                          />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      {zeroData ? (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{ height: 60 }}
                          description={
                            <span className="text-muted fw-bold">
                              {zeroData}
                            </span>
                          }
                        />
                      ) : null}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </KTCardBody>
      </div>
      {isUpdateModalOpen && (
        <UpdatePaymentModal
          isOpen={isUpdateModalOpen}
          onClose={handleUpdateCloseModal}
          handleUpdatePayment={handleUpdatePayment}
          id={selectedPaymentId}
          payment={paymentData.find(
            (payment) => payment.id === selectedPaymentId
          )}
        />
      )}
    </div>
  );
};

export { PaymentComponent };
