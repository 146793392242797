// firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyD0-ahGyufKwK0zgiOqQGTqT70g8q1Sjfs",
  authDomain: "baba-market-project.firebaseapp.com",
  projectId: "baba-market-project",
  storageBucket: "baba-market-project.appspot.com",
  messagingSenderId: "1072080388272",
  appId: "1:1072080388272:web:b9f3c04fe3be2fc88bbb75",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export { messaging };

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log(permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BPXH1a6jJuxt6Rz0jLwfFyC4FKradNSWdIFVEMZnBGDd3S6xurFoMuIv_APRwe2hEqII9fxYUULueFTF5rLdqWE",
    });
    console.log(token);
  }
};
