import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { PageTitle } from "../../../../_metronic/layout/core";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { AddSubSubCategoryModal } from "./modal/AddSubSubCategoryModal";
import { Loading } from "../../global/Loading";
import { UpdateSubSubCategoryModal } from "./modal/UpdateSubSubCategoryModal";
import { MarketplaceSkeleton } from "../skeleton/MarketPlaceSkeleton";
import { Pagination } from "../../global/Pagination";
import { DeleteModal } from "../../global/DeleteModal";
import SearchInput from "../../global/SearchInput";
import { AlertMessage } from "../../global/AlertMessage";
import { Empty, Spin } from "antd";

interface SubCategory {
  id: number;
  name: string;
  is_active: boolean;
}

type MetaData = {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
};

const SubSubCategory: React.FC = () => {
  const level = "sub-sub";
  const location = useLocation();
  const { currentUser } = useAuth();
  const [subsubcategoryData, setSubSubCategoryData] = useState<SubCategory[]>(
    []
  );
  const [selectedSubSubCategoryId, setSelectedSubSubCategoryId] = useState<
    number | null
  >(null);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const token = currentUser?.token;
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [meta, setMetaData] = useState<MetaData>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [zeroData, setZeroData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const { name, name1, type, parent_id } = (location.state as {
    subCategories: SubCategory[];
    type: string;
    parent_id: number;
    name: string;
    name1: string;
  }) || { subCategories: [], name: "", name1: "", type: "", id: 0 };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateOpenModal = () => {
    setIsUpdateModalOpen(true);
  };

  const handleUpdateCloseModal = () => {
    setIsUpdateModalOpen(false);
  };

  useEffect(() => {
    if (token) {
      GetSubSubCategory();
    }
  }, [token, meta.page, meta.per_page, searchQuery]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlert]);

  const GetSubSubCategory = async () => {
    setLoading(true);
    const resDataCategory = await API.GetCategory(
      token,
      searchQuery,
      level,
      parent_id,
      meta.page,
      type,
      meta.per_page
    );
    if (resDataCategory && resDataCategory.data.length > 0) {
      setSubSubCategoryData(resDataCategory.data);
      setMetaData(resDataCategory.meta);
    } else {
      setSubSubCategoryData([]);
      setZeroData(resDataCategory.data.message);
    }
    setLoading(false);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    const newIndex = (meta.page - 1) * meta.per_page;
    setStartIndex(newIndex);
  }, [meta.page, meta.per_page]);

  const handleAddSubCategory = async (formData: any) => {
    setLoading(true);

    const { name } = formData;

    const formDataAPI = new FormData();

    formDataAPI.append("parent_id", parent_id.toString());
    formDataAPI.append("level", level);
    formDataAPI.append("type", type);
    formDataAPI.append("name", name);

    const resAddCategory = await API.AddCategory(formDataAPI, token);

    if (resAddCategory) {
      await GetSubSubCategory();
      setShowAlert(true);
      setSuccessMessage(resAddCategory.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resAddCategory.data.message);
    }
    setLoading(false);
  };

  const handleUpateSubSubCategory = async (formData: any, id: number) => {
    setLoading(true);

    const { name } = formData;

    const formDataAPI = new FormData();
    formDataAPI.append("parent_id", parent_id.toString());
    formDataAPI.append("level", level);
    formDataAPI.append("type", type);
    formDataAPI.append("name", name);

    const resUpdateSubSubCategory = await API.UpdateCategory(
      formDataAPI,
      token,
      id
    );

    if (resUpdateSubSubCategory) {
      await GetSubSubCategory();
      setShowAlert(true);
      setSuccessMessage(resUpdateSubSubCategory.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resUpdateSubSubCategory.data.message);
    }
    setLoading(false);
  };

  const handleDeleteSubSubCategory = async (id: number) => {
    const resDeketeSubSubCategory = await API.DeleteCategory(token, id);
    if (resDeketeSubSubCategory) {
      await GetSubSubCategory();
      setShowAlert(true);
      setSuccessMessage(resDeketeSubSubCategory.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resDeketeSubSubCategory.data.message);
    }
  };

  const handleToggleActive = async (id: number) => {
    const resUpdateStatusSubSubCategory = await API.UpdateStatusCategory(
      id,
      token
    );
    if (resUpdateStatusSubSubCategory) {
      setSubSubCategoryData((prevSubSubCategoryData) => {
        return prevSubSubCategoryData.map((category) => {
          if (category.id === id) {
            return { ...category, is_active: !category.is_active };
          }
          return category;
        });
      });
      setShowAlert(true);
      setSuccessMessage(resUpdateStatusSubSubCategory.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resUpdateStatusSubSubCategory.data.message);
    }
  };

  return (
    <>
      <div className="card mb-xl-10">
        <div className="card mb-xl-8">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">List Sub-Sub Kategori ({name})</h3>
            </div>
          </div>
          <div className="separator separator mb-4"></div>
          {showAlertError && errorMessage && (
            <AlertMessage type="error" message={errorMessage} />
          )}

          {showAlert && successMessage && (
            <AlertMessage type="success" message={successMessage} />
          )}

          <div className="card-header border-0 pt-5">
            <div className="card-toolbar">
              <button
                type="button"
                className="btn btn-sm btn-light-primary me-4"
                onClick={() => window.history.back()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr002.svg"
                  className="svg-icon-2"
                />
              </button>
              <button
                type="button"
                className="btn btn-sm btn-light-primary"
                onClick={handleOpenModal}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                Tambah Sub Sub Kategori
              </button>
              {isModalOpen && (
                <AddSubSubCategoryModal
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  handleAddSubCategory={handleAddSubCategory}
                  loading={loading}
                />
              )}

              {isUpdateModalOpen && (
                <UpdateSubSubCategoryModal
                  isOpen={isUpdateModalOpen}
                  onClose={handleUpdateCloseModal}
                  handleUpateSubSubCategory={handleUpateSubSubCategory}
                  id={selectedSubSubCategoryId}
                  category={subsubcategoryData.find(
                    (category) => category.id === selectedSubSubCategoryId
                  )}
                />
              )}
            </div>
            <div className="d-flex align-items-center position-relative">
              <SearchInput placeholder="Search" onSearch={handleSearch} />
            </div>
          </div>
          <div className="card-body py-3">
            <div className="table-responsive">
              <table
                id="kt_table_users"
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              >
                <thead>
                  <tr className="text-muted fw-bolder fs-7 text-uppercase bg-light text-center">
                    <th className="min-w-60px rounded-start">No</th>
                    <th className="min-w-125px">Nama</th>
                    <th className="min-w-125px">Status</th>
                    <th className="pe-4 min-w-20px rounded-end">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan={8}
                        className="text-center text-muted fw-bold fs-6"
                      >
                        <Spin size="default" />
                      </td>
                    </tr>
                  ) : subsubcategoryData.length > 0 ? (
                    subsubcategoryData.map((subsubCategory, index) => (
                      <tr key={subsubCategory.id}>
                        <td>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center flex-column">
                              <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                                {startIndex + index + 1}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="d-flex justify-content-start flex-column">
                            <span className="text-dark fw-bold fs-6">
                              {subsubCategory.name}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                            <input
                              className="form-check-input h-30px w-60px"
                              checked={subsubCategory.is_active}
                              onClick={() =>
                                handleToggleActive(subsubCategory.id)
                              }
                              type="checkbox"
                              value=""
                              id={`flexSwitch${subsubCategory.id}`}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <button
                            onClick={() => {
                              setSelectedSubSubCategoryId(subsubCategory.id);
                              handleUpdateOpenModal();
                            }}
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-2"
                          >
                            <KTSVG
                              path="/media/icons/duotune/art/art005.svg"
                              className="svg-icon-3"
                            />
                          </button>
                          <button
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target={`#kt_modal_${subsubCategory.id}`}
                          >
                            <KTSVG
                              path="/media/icons/duotune/general/gen027.svg"
                              className="svg-icon-3"
                            />
                          </button>
                          <DeleteModal
                            id={subsubCategory.id}
                            name={`Kategori ${name1} -> ${name} -> ${subsubCategory.name}`}
                            onDelete={handleDeleteSubSubCategory}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={8}
                        className="text-center text-muted fw-bold fs-6"
                      >
                        {zeroData ? (
                          <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{ height: 60 }}
                            description={
                              <span className="text-muted fw-bold">
                                {zeroData}
                              </span>
                            }
                          />
                        ) : null}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                totalRecords={meta.total}
                recordsPerPage={meta.per_page}
                currentPage={meta.page}
                onPageChange={handlePageChange}
                onRecordsPerPageChange={handleRecordsPerPageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { SubSubCategory };
