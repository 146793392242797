import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'

interface AlertMessageProps {
  type: 'error' | 'success'
  message: string
}

const AlertMessage: React.FC<AlertMessageProps> = ({type, message}) => {
  return (
    <div className='position-fixed top-0 end-0 p-5' style={{zIndex: 1000}}>
      <div
        className={`alert ${
          type === 'error' ? 'alert-danger' : 'alert-success'
        } d-flex align-items-center`}
      >
        <span
          className={`svg-icon svg-icon-2hx svg-icon-${
            type === 'error' ? 'danger' : 'success'
          } me-3`}
        >
          <KTSVG
            path='/media/icons/duotune/general/gen048.svg'
            className={`svg-icon svg-icon-3x svg-icon-${type === 'error' ? 'danger' : 'success'}`}
          />
        </span>
        <div className='d-flex flex-column'>
          <h5 className='mb-1'>{type === 'error' ? 'Error!' : 'Success!'}</h5>
          <span>{message}</span>
        </div>
      </div>
    </div>
  )
}
export {AlertMessage}
