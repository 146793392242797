import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

const ChatHeader: FC<{ onRoleChange: (role: string) => void }> = ({
  onRoleChange,
}) => {
  const location = useLocation();

  return (
    <div className="card mb-5 mb-md-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">List Chat</h3>
        </div>
      </div>
      <div className="separator separator"></div>
      <div className="card-body pt-2 pb-0">
        <div className="d-flex justify-content-center align-items-center">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder align-items-center w-100 text-center">
            <li className="nav-item flex-fill align-items-center justify-content-center">
              <Link
                className={
                  `nav-link flex-fill text-active-primary align-items-center justify-content-center ` +
                  (location.pathname.includes(
                    "/marketplace/percakapan/pembeli"
                  ) && "active")
                }
                to="/marketplace/percakapan/pembeli"
                onClick={() => onRoleChange("buyer")}
              >
                Pembeli
              </Link>
            </li>
            <li className="nav-item flex-fill align-items-center justify-content-center">
              <Link
                className={
                  `nav-link flex-fill text-active-primary align-items-center justify-content-center ` +
                  (location.pathname.includes(
                    "/marketplace/percakapan/penjual"
                  ) && "active")
                }
                to="/marketplace/percakapan/penjual"
                onClick={() => onRoleChange("seller")}
              >
                Penjual
              </Link>
            </li>
            <li className="nav-item flex-fill align-items-center justify-content-center">
              <Link
                className={
                  `nav-link flex-fill text-active-primary align-items-center justify-content-center ` +
                  (location.pathname.includes(
                    "/marketplace/percakapan/ekspedisi"
                  ) && "active")
                }
                to="/marketplace/percakapan/ekspedisi"
                onClick={() => onRoleChange("exp")}
              >
                Ekspedisi
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { ChatHeader };
