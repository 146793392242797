import React, { useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import API from "../../../../utils/apiProvider";
import { useAuth } from "../../auth";
import { Loading } from "../../global/Loading";
import { AlertMessage } from "../../global/AlertMessage";
import { Skeleton } from "antd";

const EditProfile: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [previewPhoto, setPreviewPhoto] = useState<string | null>(null);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const MAX_FILE_SIZE_MB = 2;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  const [userData, setUserData] = useState<{
    name: string;
    phone: number;
    email: string;
    photo: string;
  } | null>(null);

  useEffect(() => {
    if (token) {
      getProfile();
    }
  }, [token]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlert, setShowAlert]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlertError, setShowAlertError]);

  const getProfile = async () => {
    setLoading(true);
    try {
      const resProfileData = await API.GetProfile(token);
      if (resProfileData && resProfileData.data) {
        setUserData(resProfileData.data);
      } else {
        console.error("Error fetching profile data:", resProfileData);
      }
    } catch (error) {
      console.error("An error occurred while fetching profile data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpateProfile = async () => {
    setLoading(true);

    const { name, phone, email } = userData || {};
    const formDataAPI = new FormData();

    if (photoFile) {
      formDataAPI.append("photo", photoFile);
    }
    formDataAPI.append("name", name || "");
    formDataAPI.append("phone", phone !== undefined ? phone.toString() : "");
    formDataAPI.append("email", email || "");

    try {
      const resUpdateCategory = await API.UpdateProfile(formDataAPI, token);

      if (resUpdateCategory) {
        await getProfile();
        setShowAlert(true);
        setSuccessMessage(resUpdateCategory.message);
        setLoading(false);
      } else {
        setErrorMessage(resUpdateCategory.data.message);
        setShowAlertError(true);
      }
    } catch (error) {
      console.error("An error occurred while updating profile:", error);
      setLoading(false);
    }
  };

  const handleInputChange = (fieldName: string, value: string | number) => {
    setUserData(
      (
        prevData: {
          name: string;
          phone: number;
          email: string;
          photo: string;
        } | null
      ) => ({
        ...(prevData || { name: "", phone: 0, email: "", photo: "" }),
        [fieldName]: value,
      })
    );
  };

  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
        setErrorMessage(
          `Ukuran file harus kurang dari ${MAX_FILE_SIZE_MB} MB.`
        );
        setShowAlertError(true);
      } else {
        setPhotoFile(file);
        setPreviewPhoto(URL.createObjectURL(file));
        setErrorMessage("");
        setShowAlert(false);
      }
    }
  };

  return (
    <div className="card mb-5 mb-xl-10">
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}

      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Ubah Profil</h3>
        </div>
      </div>

      <div id="kt_account_profile_details" className="collapse show">
        <form noValidate className="form">
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bolder fs-6">
                Foto Profil
              </label>
              <div className="col-lg-8">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                >
                  {loading ? (
                    <Skeleton.Avatar size={130} shape="circle" active />
                  ) : (
                    <div
                      className="image-input-wrapper w-125px h-125px"
                      style={{
                        borderRadius: "100%",
                        backgroundImage: `url(${
                          previewPhoto || (userData ? userData.photo : "")
                        })`,
                      }}
                    ></div>
                  )}
                  <label
                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="change"
                  >
                    <i className="bi bi-pencil-fill fs-7"></i>
                    <input
                      type="file"
                      name="icon"
                      accept=".png, .jpg, .jpeg"
                      onChange={handlePhotoChange}
                    />
                  </label>
                </div>
                <div className="form-text">
                  Tipe file yang diizinkan: png, jpg, jpeg. Ukuran maksimum
                  file: {MAX_FILE_SIZE_MB} MB.
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bolder fs-6">
                Nama
              </label>

              <div className="col-lg-8 fv-row">
                {loading ? (
                  <Skeleton.Input
                    style={{ width: "100%", height: 46 }}
                    size="large"
                    active
                    block
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Masukkan nama"
                    value={userData ? userData.name : ""}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                  />
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                Nomor Telepon
              </label>

              <div className="col-lg-8 fv-row">
                {loading ? (
                  <Skeleton.Input
                    style={{ width: "100%", height: 46 }}
                    size="large"
                    active
                    block
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Masukkan nomor telepon"
                    value={userData ? userData.phone : ""}
                    onChange={(e) => handleInputChange("phone", e.target.value)}
                  />
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bolder fs-6">
                Email
              </label>

              <div className="col-lg-8 fv-row">
                {loading ? (
                  <Skeleton.Input
                    style={{ width: "100%", height: 46 }}
                    size="large"
                    active
                    block
                  />
                ) : (
                  <input
                    type="email"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Masukkan email"
                    value={userData ? userData.email : ""}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                )}
              </div>
            </div>

            <div className="separator separator-dashed my-6"></div>

            <div className="d-flex justify-content-end">
              <button
                onClick={async () => {
                  if (true) {
                    await handleUpateProfile();
                  }
                }}
                className="btn btn-primary"
                disabled={loading}
              >
                {!loading && "Simpan"}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Sabar boss...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { EditProfile };
