import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import Select from 'react-select'
import {FC} from 'react'

interface AddSubCategoryModalProps {
  isOpen: boolean
  onClose: () => void
  handleAddSubCategory: (formData: any) => void
}
const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

const AddSubCategoryModal: FC<AddSubCategoryModalProps> = ({
  isOpen,
  onClose,
  handleAddSubCategory,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    fee: '',
  })

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  //   const handleIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     handleFileUpload(e.target.files)
  //   }

  //   const handleFileUpload = (files: FileList | null) => {
  //     if (files && files[0]) {
  //       const file = files[0]
  //       const fileURL = URL.createObjectURL(file)
  //       setFormData({
  //         ...formData,
  //         icon: file,
  //         iconURL: fileURL,
  //       })
  //     }
  //   }

  //   const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
  //     e.preventDefault()
  //     handleFileUpload(e.dataTransfer.files)
  //   }

  const handleClose = () => {
    setFormData({
      name: '',
      fee: '',
    })
    onClose()
  }

  const options = [
    {value: 'material', label: 'Material'},
    {value: 'non-material', label: 'Non-Material'},
  ]

  return (
    <>
      {isOpen && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>Tambah Sub Kategori</h2>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    aria-label='Close'
                    onClick={handleClose}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </button>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <form id='kt_modal_add_user_form' className='form' noValidate>
                    {/* <div
                      className='fv-row mb-7'
                      id='drop-zone'
                      onDrop={handleDrop}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <label className='d-block fw-bold fs-6 mb-2 required'>Icon</label>
                      <div
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{backgroundImage: `url('${formData.icon || blankImg}')`}}
                      >
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{backgroundImage: `url('${formData.iconURL || blankImg}')`}}
                        ></div>
                        <label
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='change'
                          data-bs-toggle='tooltip'
                          title='Change avatar'
                        >
                          <i className='bi bi-pencil-fill fs-7'></i>
                          <input
                            type='file'
                            name='icon'
                            accept='.png, .jpg, .jpeg'
                            onChange={handleIconChange}
                          />
                          <input type='hidden' name='avatar_remove' />
                        </label>
                        <span
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='cancel'
                          data-bs-toggle='tooltip'
                          title='Cancel avatar'
                        >
                          <i className='bi bi-x fs-2'></i>
                        </span>
                      </div>
                      <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                    </div> */}

                    {/* <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Tipe Kategori</label>
                      <select
                        className='form-select form-select-solid'
                        aria-label='Select example'
                        value={formData.name} // Menetapkan nilai formData.type sebagai value dari select
                        onChange={(e) => setFormData({...formData, type: e.target.value})} // Menetapkan nilai yang dipilih langsung ke formData.type
                      >
                        <option value=''>Open this select menu</option>
                        <option value='material'>Material</option>
                        <option value='non-material'>Non Material</option>
                      </select>
                    </div> */}
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Nama Sub Kategori</label>
                      <input
                        placeholder='Masukkan nama sub kategori'
                        className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                        type='text'
                        name='name'
                        value={formData.name}
                        onChange={handleChange}
                        autoComplete='off'
                      />
                    </div>

                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Fee Seller</label>
                      <input
                        placeholder='Masukkan persentase'
                        className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                        type='number'
                        name='fee'
                        value={formData.fee}
                        onChange={handleChange}
                        autoComplete='off'
                      />
                    </div>
                  </form>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' onClick={handleClose}>
                    Tutup
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={async () => {
                      await handleAddSubCategory(formData)
                      onClose()
                    }}
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {AddSubCategoryModal}
