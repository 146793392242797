import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {generateTransactionBreadcrumbs} from '../../accounts/AccountPage'
import {useAuth} from '../../auth'
import {PageTitle} from '../../../../_metronic/layout/core'
import {EditProduct} from './EditProduct'
import API from '../../../../utils/apiProvider'

const PageEditProduct: React.FC = () => {
  const {currentUser} = useAuth()
  const token = currentUser?.token
  const location = useLocation()
  const {id} = (location.state as {id: number}) || {id: 0}
  const [loading, setLoading] = useState(false)
  const [zeroData, setZeroData] = useState('')
  const [productDetail, setProductDetail] = useState<any>([])

  useEffect(() => {
    if (currentUser?.token) {
      getProductDetail(id)
    }
  }, [currentUser])

  const getProductDetail = async (id: number) => {
    setLoading(true)

    try {
      const resProductDetail = await API.GetProductDetail(token, id)
      if (resProductDetail.data) {
        setProductDetail(resProductDetail.data)
      } else {
        setZeroData('Produk tidak ditemukan!')
      }
    } catch (error) {
      console.error('Error fetching product detail:', error)
      setZeroData('Terjadi kesalahan saat memuat detail produk')
    }

    setLoading(false)
  }

  return <EditProduct id={id} />
}

export default PageEditProduct
