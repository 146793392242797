/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {MainCategory} from '../../modules/marketplace/category/MainCategory'
import usePageTitle from '../../modules/global/PageTitle'

const KategoriPage: FC = () => (
  <>
    <div className='card mb-xl-10'>
      <MainCategory className='mb-xl-8' />
    </div>
  </>
)

const KategoriWrapper: FC = () => {
  const intl = useIntl()
  usePageTitle('Kategori')

  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <KategoriPage />
    </>
  )
}

export {KategoriWrapper}
