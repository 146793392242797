/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div> */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundColor: '#CE464A'}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <div
            className='d-flex flex-column align-items-center mb-12'
            style={{
              userSelect: 'none',
              WebkitUserSelect: 'none',
              MozUserSelect: 'none',
              msUserSelect: 'none',
            }}
          >
            <Link to='/' className='mb-0'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/icon.png')}
                className='h-150px'
                style={{marginRight: 16}}
              />
            </Link>
            <p
              style={{
                fontSize: 38,
                color: 'white',
                margin: -24,
                fontFamily: 'NunitoSans',
                fontWeight: 'bold',
              }}
              className='fw-bold'
            >
              Baba Market
            </p>
          </div>
          {/* end::Logo */}

          {/* begin::Image */}
          {/* <img
          className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
          src={toAbsoluteUrl('/media/misc/auth-screens.png')}
          alt=''
        /> */}
          {/* end::Image */}

          {/* begin::Title */}
          {/* <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
          Fast, Efficient and Productive
        </h1> */}
          {/* end::Title */}

          {/* begin::Text */}
          <div
            className='text-white text-center fs-4'
            style={{
              userSelect: 'none',
              WebkitUserSelect: 'none',
              MozUserSelect: 'none',
              msUserSelect: 'none',
              fontFamily: 'NunitoSans',
              fontWeight: 'normal',
            }}
          >
            Rumah Digital untuk Kebutuhan Bangunan dan Bisnis Anda! Dari peralatan konstruksi hingga
            material bangunan, kami siap memenuhi setiap kebutuhan Anda. Temukan beragam produk
            berkualitas tinggi, layanan yang andal, dan penawaran spesial yang menarik.
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
