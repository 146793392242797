import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

type Props = {
  show: boolean;
  handleClose: () => void;
  handleSave: (title: string, date: string) => void;
  eventTitle: string;
  eventDate: string;
};

const EditEventModal: React.FC<Props> = ({
  show,
  handleClose,
  handleSave,
  eventTitle,
  eventDate,
}) => {
  const [title, setTitle] = useState(eventTitle);
  const [date, setDate] = useState(eventDate);

  const handleSubmit = () => {
    handleSave(title, date);
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Hari Libur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Nama Hari Libur</Form.Label>
            <Form.Control
              type="text"
              placeholder="Masukkan nama hari libur"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Tanggal Hari Libur</Form.Label>
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-flex btn-sm btn-light-danger"
          onClick={handleClose}
        >
          Batal
        </button>
        <button
          className="btn btn-flex btn-sm btn-danger"
          onClick={handleSubmit}
        >
          Simpan Hari Libur
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditEventModal;
