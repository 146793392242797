/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import API from "../../../../utils/apiProvider";
import { useAuth } from "../../auth";
import { AddUserModal } from "./modal/AddUserModal";
import * as Yup from "yup";
import { UserSkeleton } from "./skeleton/SkeletonUser";
import { AlertMessage } from "../../global/AlertMessage";
import { Pagination } from "../../global/Pagination";
import { DeleteModal } from "../../global/DeleteModal";
import Button from "../../global/Button";
import { Link } from "react-router-dom";
import { UpdateUserModal } from "./modal/UpdateUserModal";
import { Empty, Image, Spin } from "antd";
import SearchInput from "../../global/SearchInput";

const addUserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nama harus terdiri dari minimal 2 karakter")
    .max(50, "Nama tidak boleh lebih dari 50 karakter")
    .required("Nama wajib diisi"),
  phone: Yup.string()
    .matches(/^\d+$/, "Nomor telepon harus berisi hanya angka")
    .min(10, "Nomor telepon harus terdiri dari minimal 10 digit")
    .max(15, "Nomor telepon tidak boleh lebih dari 15 digit")
    .required("Nomor telepon wajib diisi"),
  email: Yup.string()
    .email("Format email tidak valid")
    .required("Email wajib diisi"),
  password: Yup.string()
    .min(6, "Password harus terdiri dari minimal 6 karakter")
    .max(20, "Password tidak boleh lebih dari 20 karakter")
    .required("Password wajib diisi"),
});

type UserData = {
  id: number;
  photo: string;
  code: string;
  name: string;
  email: string;
  phone: string;
  join_date: string;
  last_activity: string;
  is_active: boolean;
};

type MetaData = {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
};

const UserMultiPage = ({
  pageType,
}: {
  pageType: "manager" | "accounting" | "admin_exp" | "exp" | "buyer" | "seller";
}) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<UserData[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [startIndex, setStartIndex] = useState(0);
  const [meta, setMetaData] = useState<MetaData>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [dataFetched, setDataFetched] = useState(false);
  const [selectUserId, setSelectUserId] = useState<number | null>(null);
  const [zeroData, setZeroData] = useState("");
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateOpenModal = () => {
    setIsUpdateModalOpen(true);
  };

  const handleUpdateCloseModal = () => {
    setIsUpdateModalOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    if (currentUser?.token) {
      GetProfile();
      const newIndex = (meta.page - 1) * meta.per_page;
      setStartIndex(newIndex);
    }

    let timeout: NodeJS.Timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    } else if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => clearTimeout(timeout);
  }, [
    currentUser?.token,
    meta.page,
    meta.per_page,
    pageType,
    showAlert,
    showAlertError,
    searchQuery,
  ]);

  useEffect(() => {
    setDataFetched(false);
    setSearchQuery("");
    setMetaData((prevMetaData) => ({ ...prevMetaData, page: 1 }));
  }, [pageType]);

  const GetProfile = async () => {
    setLoading(true);

    const resUserData = await API.GetUser(
      currentUser?.token,
      searchQuery,
      pageType,
      meta.page,
      meta.per_page
    );

    if (resUserData.data && resUserData.data.length > 0) {
      const sortedData = resUserData.data.sort(
        (a: UserData, b: UserData) => a.id - b.id
      );
      setMetaData(resUserData.meta);
      setUserData(sortedData);
    } else {
      setUserData([]);
      setZeroData(resUserData.data.message);
    }
    setDataFetched(true);
    setLoading(false);
  };

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
  };

  const handleAddUser = async (formData: any) => {
    setLoading(true);
    await addUserSchema.validate(formData, { abortEarly: false });
    const { name, phone, email, password } = formData;

    const formDataAPI = new FormData();
    formDataAPI.append("role", pageType);
    formDataAPI.append("name", name);
    formDataAPI.append("phone", phone);
    formDataAPI.append("email", email);
    formDataAPI.append("password", password);

    if (pageType === "exp") {
      const { code } = formData;
      formDataAPI.append("code", code);
    }

    const resAddUser = await API.AddUser(formDataAPI, currentUser?.token);

    if (resAddUser) {
      await GetProfile();
      setShowAlert(true);
      setSuccessMessage(resAddUser.message);
      setLoading(false);
    } else {
      setShowAlertError(true);
      setErrorMessage(resAddUser.data.message);
    }
  };

  const handleUpdateUser = async (formData: any, id: number) => {
    setLoading(true);

    const { name, phone, email, password, password_confirmation } = formData;

    const formDataAPI = new FormData();
    formDataAPI.append("name", name);
    formDataAPI.append("phone", phone);
    formDataAPI.append("email", email);
    formDataAPI.append("password", password);
    formDataAPI.append("password_confirmation", password_confirmation);

    const resUpdateUser = await API.UpdateUser(
      formDataAPI,
      currentUser?.token,
      id
    );

    if (resUpdateUser) {
      setSuccessMessage(resUpdateUser.message);
      setShowAlert(true);
      setLoading(false);
      await GetProfile();
    } else {
      setErrorMessage(resUpdateUser.data.message);
      setShowAlertError(true);
    }
  };

  const handleExport = async () => {
    setLoading(true);
    const resExportUser = await API.ExportUser(pageType, currentUser?.token);

    if (resExportUser && resExportUser instanceof ArrayBuffer) {
      await GetProfile();
      const url = window.URL.createObjectURL(new Blob([resExportUser]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `users_${pageType}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowAlert(true);
      setSuccessMessage("File berhasil didownload");
      setLoading(false);
    } else {
      setShowAlertError(true);
      setErrorMessage("File gagal didwonload");
    }
  };

  const handleToggleActive = async (id: number) => {
    const resUpdateStatusUser = await API.UpdateStatusUser(
      currentUser?.token,
      id
    );
    if (resUpdateStatusUser) {
      setUserData((prevAllUserData) => {
        return prevAllUserData.map((user) => {
          if (user.id === id) {
            return { ...user, is_active: !user.is_active };
          }
          return user;
        });
      });
      setShowAlert(true);
      setSuccessMessage(resUpdateStatusUser.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resUpdateStatusUser.data.message);
    }
  };

  const handleDeleteUser = async (id: number) => {
    const resDeleteUsers = await API.DeleteUser(currentUser?.token, id);
    if (resDeleteUsers) {
      await GetProfile();
      setSuccessMessage(resDeleteUsers.message);
      setShowAlert(true);
      setLoading(false);
    } else {
      setShowAlertError(true);
      setErrorMessage(resDeleteUsers.data.message);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const renderUserData = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={9} className="text-center text-muted fw-bold fs-6">
            <Spin size="default" />
          </td>
        </tr>
      );
    } else if (userData.length === 0) {
      return (
        <tr>
          <td colSpan={9} className="text-center text-muted">
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={
                <span className="text-muted fw-bold">{zeroData}</span>
              }
            />
          </td>
        </tr>
      );
    } else {
      return userData.map((user, index) => (
        <tr key={user.id}>
          <td className="text-center">
            <span className="text-muted fw-bold fs-6">
              {startIndex + index + 1}
            </span>
          </td>
          <td>
            <div className="d-flex align-items-center">
              <div className="symbol symbol-44px me-5">
                <Image
                  src={user.photo}
                  className="rounded"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>

              <div className="d-flex justify-content-start flex-column">
                <span className="text-dark fw-bold mb-1 fs-6">{user.name}</span>
                <span className="text-muted fw-semibold text-muted d-block fs-7">
                  {user.email}
                </span>
              </div>
            </div>
          </td>
          {pageType === "exp" && (
            <td className="text-center">
              <span className="text-muted fw-bold d-block mb-1 fs-6 text-center">
                {user.code}
              </span>
            </td>
          )}
          <td className="text-center">
            <span className="text-muted fw-bold d-block fs-6 text-center">
              {user.phone}
            </span>
          </td>
          <td className="text-center">
            <span className="badge badge-light-primary fs-7 fw-semibold">
              {user.last_activity}
            </span>
          </td>
          <td className="text-center">
            <span className="text-muted fw-bold fs-6">{user.join_date}</span>
          </td>
          {pageType === "exp" && (
            <td className="text-center">
              <Link
                to="/pengguna/ekspedisi/price"
                state={{
                  id: user.id,
                  name: user.name,
                  code: user.code,
                }}
                className="btn btn-sm btn-light-primary"
              >
                <KTSVG
                  path="/media/icons/duotune/coding/cod001.svg"
                  className="svg-icon-2"
                />
                Setup Harga
              </Link>
            </td>
          )}

          <td>
            <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
              <input
                className="form-check-input h-30px w-60px"
                type="checkbox"
                checked={user.is_active}
                value=""
                onChange={() => handleToggleActive(user.id)}
                id={`flexSwitch${user.id}`}
              />
            </div>
          </td>

          {pageType === "buyer" || pageType === "seller" ? (
            <td className="text-center">
              <button
                onClick={() => {
                  setSelectUserId(user.id);
                  handleUpdateOpenModal();
                }}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
              >
                <KTSVG
                  path="/media/icons/duotune/art/art005.svg"
                  className="svg-icon-3"
                />
              </button>
              <Link
                to={
                  pageType === "buyer"
                    ? "/pengguna/pembeli/transaksi"
                    : "/pengguna/penjual/transaksi"
                }
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                state={{
                  id: user.id,
                  name: user.name,
                }}
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen032.svg"
                  className="svg-icon-2"
                />
              </Link>
            </td>
          ) : pageType === "exp" ? (
            <td className="text-center">
              <button
                onClick={() => {
                  setSelectUserId(user.id);
                  handleUpdateOpenModal();
                }}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
              >
                <KTSVG
                  path="/media/icons/duotune/art/art005.svg"
                  className="svg-icon-3"
                />
              </button>
              <button
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                type="button"
                data-bs-toggle="modal"
                data-bs-target={`#kt_modal_${user.id}`}
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen027.svg"
                  className="svg-icon-3"
                />
              </button>
              <DeleteModal
                id={user.id}
                name={user.name}
                onDelete={handleDeleteUser}
              />
            </td>
          ) : (
            <td className="text-center">
              <button
                onClick={() => {
                  setSelectUserId(user.id);
                  handleUpdateOpenModal();
                }}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
              >
                <KTSVG
                  path="/media/icons/duotune/art/art005.svg"
                  className="svg-icon-3"
                />
              </button>
              <button
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-6"
                type="button"
                data-bs-toggle="modal"
                data-bs-target={`#kt_modal_${user.id}`}
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen027.svg"
                  className="svg-icon-3"
                />
              </button>
              <DeleteModal
                id={user.id}
                name={user.name}
                onDelete={handleDeleteUser}
              />
            </td>
          )}
        </tr>
      ));
    }
  };

  return (
    <>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}
      <div className="card mb-xl-10">
        <div className="card mb-xl-8">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{`List ${
                pageType === "manager"
                  ? "Manager"
                  : pageType === "accounting"
                  ? "Akuntansi"
                  : pageType === "exp"
                  ? "Ekspedisi"
                  : pageType === "admin_exp"
                  ? "Admin Ekspedisi"
                  : pageType === "buyer"
                  ? "Pembeli"
                  : "Penjual"
              }`}</h3>
            </div>
          </div>
          <div className="separator separator"></div>
          <div className="card-header border-0 pt-6">
            <div className="card-toolbar d-flex align-items-center">
              <div className="d-flex align-items-center position-relative">
                {pageType !== "buyer" && pageType !== "seller" && (
                  <Button
                    onClick={handleOpenModal}
                    iconPath="/media/icons/duotune/arrows/arr075.svg"
                    buttonText={`Tambah ${
                      pageType === "manager"
                        ? "Manager"
                        : pageType === "accounting"
                        ? "Akuntansi"
                        : pageType === "exp"
                        ? "Ekspedisi"
                        : "Admin Ekspedisi"
                    }`}
                  />
                )}

                {isModalOpen &&
                  (pageType === "manager" ||
                    pageType === "accounting" ||
                    pageType === "exp" ||
                    pageType === "admin_exp") && (
                    <AddUserModal
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                      handleAddUser={handleAddUser}
                      formType={pageType}
                    />
                  )}

                {isUpdateModalOpen &&
                  (pageType === "manager" ||
                    pageType === "accounting" ||
                    pageType === "admin_exp" ||
                    pageType === "buyer" ||
                    pageType === "seller" ||
                    pageType === "exp") && (
                    <UpdateUserModal
                      isOpen={isUpdateModalOpen}
                      onClose={handleUpdateCloseModal}
                      handleUpdateUser={handleUpdateUser}
                      id={selectUserId}
                      user={userData.find((user) => user.id === selectUserId)}
                      formType={pageType}
                    />
                  )}
              </div>

              <div className="d-flex align-items-center position-relative">
                <Button
                  onClick={handleExport}
                  iconPath="/media/icons/duotune/coding/cod002.svg"
                  buttonText={`Ekspor ${
                    pageType === "manager"
                      ? "Manager"
                      : pageType === "accounting"
                      ? "Akuntansi"
                      : pageType === "exp"
                      ? "Ekspedisi"
                      : pageType === "admin_exp"
                      ? "Admin Ekspedisi"
                      : pageType === "buyer"
                      ? "Pembeli"
                      : "Penjual"
                  }`}
                />
              </div>
            </div>
            <div className="d-flex align-items-center position-relative">
              <SearchInput onSearch={handleSearch} placeholder="Search" />
            </div>
          </div>
          <div className="card-body py-3">
            <div className="table-responsive">
              <table
                id="kt_table_users"
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              >
                <thead>
                  <tr className="text-muted fw-bolder fs-7 text-uppercase bg-light text-center">
                    <th className="min-w-60px rounded-start">No</th>
                    <th className="min-w-125px">Nama</th>
                    {pageType === "exp" && (
                      <th className="min-w-125px">Kode</th>
                    )}
                    <th className="min-w-125px">Nomor</th>
                    <th
                      className={`min-w-${
                        pageType === "exp" ? "200" : "125"
                      }px`}
                    >
                      Aktivitas Terakhir
                    </th>

                    <th
                      className={`min-w-${
                        pageType === "exp" ? "200" : "125"
                      }px`}
                    >
                      Tanggal Bergabung
                    </th>
                    {pageType === "exp" && (
                      <th className="min-w-200px">Setup</th>
                    )}
                    <th
                      className={`min-w-${
                        pageType !== "buyer" && pageType !== "seller"
                          ? "60"
                          : "125"
                      }px`}
                    >
                      Status
                    </th>
                    <th
                      className={`pe-6 min-w-${
                        pageType !== "buyer" &&
                        pageType !== "seller" &&
                        pageType !== "exp"
                          ? "60"
                          : "125"
                      }px text-muted rounded-end`}
                    >
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>{renderUserData()}</tbody>
              </table>
              <Pagination
                totalRecords={meta.total}
                recordsPerPage={meta.per_page}
                currentPage={meta.page}
                onPageChange={handlePageChange}
                onRecordsPerPageChange={handleRecordsPerPageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { UserMultiPage };
