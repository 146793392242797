import React, {useEffect, useState} from 'react'
import {useAuth} from '../../auth'
import API from '../../../../utils/apiProvider'
import {KTSVG} from '../../../../_metronic/helpers'
import CountUp from 'react-countup'

interface Details {
  shop: number
  category: number
  product: number
  transaction: number
}
type TaskWidget = {
  data: Details
}
const MyTasksWidget: React.FC<TaskWidget> = ({data}) => {
  return (
    <div className='col-xl-6'>
      <div className='card card-flush h-xl-100'>
        <div
          className='card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-200px'
          style={{backgroundImage: 'url("/media/svg/shapes/top-green.png'}}
          data-bs-theme='light'
        >
          <h3 className='card-title align-items-start flex-column text-white pt-15'>
            <span className='fw-bold text-light fs-2x mb-3'>Status</span>
          </h3>
        </div>
        <div className='card-body mt-n20'>
          <div className='mt-n20 position-relative'>
            <div className='row g-3 g-lg-6'>
              <div className='col-6'>
                <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='symbol symbol-30px me-5 mb-8'>
                    <span className='symbol-label'>
                      <div
                        className='btn btn-icon btn-color-danger w-35px h-35px'
                        style={{pointerEvents: 'none'}}
                      >
                        <KTSVG
                          path='/media/icons/duotune/ecommerce/ecm004.svg'
                          className='svg-icon-1'
                        />
                      </div>
                    </span>
                  </div>
                  <div className='m-0'>
                    <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                      <CountUp end={data.shop} duration={2} />
                    </span>
                    <span className='text-gray-500 fw-semibold fs-6'>Toko Aktif</span>
                  </div>
                </div>
              </div>

              <div className='col-6'>
                <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='symbol symbol-30px me-5 mb-8'>
                    <span className='symbol-label'>
                      <div
                        className='btn btn-icon btn-color-info w-35px h-35px'
                        style={{pointerEvents: 'none'}}
                      >
                        <KTSVG
                          path='/media/icons/duotune/ecommerce/ecm009.svg'
                          className='svg-icon-1'
                        />
                      </div>
                    </span>
                  </div>
                  <div className='m-0'>
                    <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                      <CountUp end={data.category} duration={2} />
                    </span>
                    <span className='text-gray-500 fw-semibold fs-6'>Kategori Aktif</span>
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='symbol symbol-30px me-5 mb-8'>
                    <span className='symbol-label'>
                      <div
                        className='btn btn-icon btn-color-primary w-35px h-35px'
                        style={{pointerEvents: 'none'}}
                      >
                        <KTSVG
                          path='/media/icons/duotune/ecommerce/ecm005.svg'
                          className='svg-icon-1'
                        />
                      </div>
                    </span>
                  </div>
                  <div className='m-0'>
                    <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                      <CountUp end={data.product} duration={2} />
                    </span>
                    <span className='text-gray-500 fw-semibold fs-6'>Produk Aktif</span>
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='symbol symbol-30px me-5 mb-8'>
                    <span className='symbol-label'>
                      <div
                        className='btn btn-icon btn-color-success w-35px h-35px'
                        style={{pointerEvents: 'none'}}
                      >
                        <KTSVG
                          path='/media/icons/duotune/graphs/gra001.svg'
                          className='svg-icon-1'
                        />
                      </div>
                    </span>
                  </div>
                  <div className='m-0'>
                    <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                      <CountUp end={data.transaction} duration={2} />
                    </span>
                    <span className='text-gray-500 fw-semibold fs-6'>Transaksi Berhasil</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {MyTasksWidget}
