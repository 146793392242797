/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG, useDebounce } from "../../../../_metronic/helpers";
import API from "../../../../utils/apiProvider";
import { useAuth } from "../../auth";
import { Link } from "react-router-dom";
import { Pagination } from "../../global/Pagination";
import { Empty, Image, Spin, Table } from "antd";
import SearchInput from "../../global/SearchInput";
import RupiahFormat from "../../global/Formatrupiah";
import { Form, Modal } from "react-bootstrap";
import { AlertMessage } from "../../global/AlertMessage";

interface Shop {
  id: number;
  photo: string;
  name: string;
}

interface Product {
  id: number;
  photo: string;
  type: string;
  name: string;
  variant: string | null;
  note: string | null;
  qty: number;
  price: number;
  width: number | null;
  length: number | null;
  height: number | null;
  volume: number | null;
  weight: string;
  subtotal: number;
}

interface ComplainTransaction {
  id: number;
  code: string;
  shop: Shop;
  products: Product[];
  discussion_available: boolean;
  discussion_count: number;
  type: string;
  problem: string;
  reason: string;
  file: string | null;
  solution: string;
  shipping: string | null;
  nominal: number;
  status: string;
  date: string;
}

interface Meta {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

const ComplainTransaction: React.FC = () => {
  const [transactionsData, setTransactionsData] = useState<
    ComplainTransaction[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [zeroData, setZeroData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleClick = (itemId: number, status: string) => {
    setSelectedItemId(itemId);
    setSelectedStatus(status);
    setShowModalUpdate(true);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }

    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  const [metaData, setMetaData] = useState<Meta>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });

  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const newIndex = (metaData.page - 1) * metaData.per_page;
    setStartIndex(newIndex);
  }, [metaData.page, metaData.per_page]);

  useEffect(() => {
    if (currentUser?.token) {
      fetchTransactions();
    }
  }, [currentUser, metaData.page, metaData.per_page, searchQuery]);

  const fetchTransactions = async () => {
    setLoading(true);
    const resTransaction = await API.GetComplainTransaction(
      token,
      metaData.page,
      metaData.per_page
    );
    if (resTransaction && resTransaction.data && resTransaction.meta) {
      setTransactionsData(resTransaction.data);
      setMetaData(resTransaction.meta);
    } else {
      setTransactionsData([]);
      setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
      setZeroData(resTransaction.data.message);
    }
    setLoading(false);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= metaData.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleSave = async () => {
    const formDataAPI = new FormData();

    formDataAPI.append("status", selectedStatus);

    const res = await API.UpdateStatusComplain(
      token,
      selectedItemId,
      formDataAPI
    );

    if (res.message === "Komplain berhasil diperbarui") {
      await fetchTransactions();
      setShowAlert(true);
      setSuccessMessage(res.message);
      setShowModalUpdate(false);
    } else {
      setErrorMessage(res.data.message);
      setShowAlertError(true);
      setShowModalUpdate(false);
    }
  };

  return (
    <div className={`card`}>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">List Komplain</h3>
        </div>
      </div>
      <div className="separator separator"></div>
      <div className="card-header border-0 pt-6">
        <div className="card-toolbar d-flex align-items-center justify-content-end w-100">
          <div className="d-flex align-items-center position-relative">
            <SearchInput placeholder="Search" onSearch={handleSearch} />
          </div>
        </div>
      </div>

      <div className="card-body py-3">
        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          >
            <thead>
              <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                <th className="min-w-60px rounded-start">No</th>
                <th className="min-w-125px">Nama Toko</th>
                <th className="min-w-125px">Kode Transaksi</th>
                <th className="min-w-125px">Tanggal</th>
                <th className="min-w-125px">Status</th>
                <th className="min-w-100x">Alasan</th>
                <th className="pe-4 min-w-125px rounded-end">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan={8}
                    className="text-center text-muted fw-bold fs-6"
                  >
                    <Spin size="default" />
                  </td>
                </tr>
              ) : transactionsData.length > 0 ? (
                transactionsData.map((transaction, index) => (
                  <tr key={transaction.id}>
                    <td className="text-center">
                      <span className="text-dark fw-bold mb-1 fs-6">
                        {startIndex + index + 1}
                      </span>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-60px me-5">
                          <span className="symbol-label bg-light">
                            <Image
                              src={transaction.shop.photo}
                              className="rounded"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "contain",
                              }}
                            />
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="text-dark fw-bold fs-6 me-2">
                            {transaction.shop.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <span className="text-dark fw-bold d-block mb-1 fs-6">
                        {transaction.code}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        {transaction.date}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        <span
                          className={`badge ${
                            transaction.status === "pending"
                              ? "badge-light-primary"
                              : transaction.status === "nego"
                              ? "badge-light-warning"
                              : transaction.status === "paid"
                              ? "badge-light-info"
                              : transaction.status === "process"
                              ? "badge-light-warning"
                              : transaction.status === "sent"
                              ? "badge-light-success"
                              : transaction.status === "success"
                              ? "badge-light-success"
                              : "badge-light-danger"
                          }`}
                        >
                          {transaction.status === "pending"
                            ? "Pending"
                            : transaction.status === "nego"
                            ? "Negosiasi"
                            : transaction.status === "paid"
                            ? "Diproses"
                            : transaction.status === "process"
                            ? "Dikemas"
                            : transaction.status === "sent"
                            ? "Dikirim"
                            : transaction.status === "success"
                            ? "Selesai"
                            : "Dibatalkan"}
                        </span>
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        {transaction.reason}
                      </span>
                    </td>
                    <td className="text-center">
                      <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                        <button
                          onClick={() =>
                            handleClick(transaction.id, transaction.status)
                          }
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                        >
                          <KTSVG
                            path="/media/icons/duotune/art/art005.svg"
                            className="svg-icon-3"
                          />
                        </button>
                        <Modal
                          show={showModalUpdate}
                          onHide={() => setShowModalUpdate(false)}
                          aria-labelledby="example-custom-modal-styling-title"
                          centered
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Update Status</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="mb-4 fv-row">
                              <label className="form-label required">
                                Status
                              </label>
                              <select
                                className="form-select mb-2"
                                data-control="select2"
                                data-placeholder="Pilih opsi status"
                                data-allow-clear="true"
                                value={selectedStatus}
                                onChange={(e) =>
                                  setSelectedStatus(e.target.value)
                                }
                              >
                                <option value="">Pilih status</option>
                                <option value="approve">Setujui</option>
                                <option value="cancel">Batalkan</option>
                                <option value="success">Selesai</option>
                              </select>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              className="btn btn-sm btn-outline btn-outline-danger btn-active-light-danger"
                              onClick={() => setShowModalUpdate(false)}
                            >
                              Batal
                            </button>
                            <button
                              className="btn btn-sm btn-danger btn-active-light-danger"
                              onClick={handleSave}
                              disabled={!selectedStatus}
                            >
                              Simpan
                            </button>
                          </Modal.Footer>
                        </Modal>

                        <td className="text-center">
                          <Link
                            to={`/marketplace/komplaintransaksi/detail/${transaction.id}`}
                            state={{
                              id: transaction.id,
                              data: transaction,
                            }}
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                          >
                            <KTSVG
                              path="/media/icons/duotune/ecommerce/ecm008.svg"
                              className="svg-icon-3"
                            />
                          </Link>
                        </td>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={8}
                    className="text-center text-muted fw-bold fs-6"
                  >
                    {zeroData ? (
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 60 }}
                        description={
                          <span className="text-muted fw-bold">{zeroData}</span>
                        }
                      />
                    ) : null}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            totalRecords={metaData.total}
            recordsPerPage={metaData.per_page}
            currentPage={metaData.page}
            onPageChange={handlePageChange}
            onRecordsPerPageChange={handleRecordsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export { ComplainTransaction };
