/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {Shop} from '../../modules/marketplace/shop/Shop'
import usePageTitle from '../../modules/global/PageTitle'

const TokoPage: FC = () => (
  <>
    <div className='card mb-xl-10'>
      <Shop className='mb-xl-8' />
    </div>
  </>
)

const TokoWrapper: FC = () => {
  usePageTitle('Toko')

  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <TokoPage />
    </>
  )
}

export {TokoWrapper}
