/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { Loading } from "../../global/Loading";
import { AddBannerModal } from "./Modal/AddBannerModal";
import { UpdateBannerModal } from "./Modal/UpdateBannerModal";
import { AlertMessage } from "../../global/AlertMessage";
import { Pagination } from "../../global/Pagination";
import { DeleteModal } from "../../global/DeleteModal";
import { MarketplaceSkeleton } from "../../marketplace/skeleton/MarketPlaceSkeleton";
import { Empty, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SearchInput from "../../global/SearchInput";

type Props = {
  className: string;
};

type CategoryData = {
  id: number;
  photo: string;
  url: string;
};

const BannerComponents: React.FC<Props> = ({ className }) => {
  const { currentUser } = useAuth();
  const [bannerData, setBannerData] = useState<CategoryData[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [zeroData, setZeroData] = useState("");
  const token = currentUser?.token;
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [metaData, setMetaData] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (currentUser?.token) {
      getBanner();
    }
  }, [currentUser, metaData.page, metaData.per_page, searchQuery]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateOpenModal = () => {
    setIsUpdateModalOpen(true);
  };

  const handleUpdateCloseModal = () => {
    setIsUpdateModalOpen(false);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }

    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  const getBanner = async () => {
    setLoading(true);
    const resDataBanner = await API.GetBanner(
      token,
      metaData.page,
      searchQuery,
      metaData.per_page
    );

    if (resDataBanner.data.length > 0 && resDataBanner.data) {
      const sortedData = resDataBanner.data.sort(
        (a: CategoryData, b: CategoryData) => a.id - b.id
      );
      setBannerData(sortedData);
      setMetaData(resDataBanner.meta);
    } else {
      setBannerData([]);
      setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
      setZeroData(resDataBanner.data.message);
    }
    setLoading(false);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= metaData.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleAddBanner = async (formData: any) => {
    const { photo, url } = formData;

    const formDataAPI = new FormData();
    formDataAPI.append("photo", photo);
    formDataAPI.append("url", url);

    const resAddBanner = await API.AddBanner(formDataAPI, token);

    if (resAddBanner) {
      await getBanner();
      setShowAlert(true);
      setSuccessMessage(resAddBanner.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resAddBanner.message);
    }
  };

  const handleUpdateBanner = async (formData: any, id: number) => {
    const { photo, url } = formData;

    const formDataAPI = new FormData();

    if (photo instanceof File) {
      formDataAPI.append("photo", photo);
    }

    formDataAPI.append("url", url);

    const resUpdateBanner = await API.UpdateBanner(formDataAPI, token, id);

    if (resUpdateBanner) {
      await getBanner();
      setShowAlert(true);
      setSuccessMessage(resUpdateBanner.message);
    } else {
      setErrorMessage(resUpdateBanner.message);
      setShowAlertError(true);
    }
  };

  const handleDeleteUser = async (id: number) => {
    const resDeleteBanner = await API.DeleteBanner(token, id);
    if (resDeleteBanner) {
      await getBanner();
      setShowAlert(true);
      setSuccessMessage(resDeleteBanner.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resDeleteBanner.data.message);
    }
  };
  const handleSearch = async (query: string) => {
    setSearchQuery(query);
  };

  return (
    <>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}

      <div className={`card ${className}`}>
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">List Banner</h3>
          </div>
        </div>
        <div className="separator separator"></div>
        <div className="card-header border-0 pt-5">
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-light-primary"
              onClick={handleOpenModal}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr075.svg"
                className="svg-icon-2"
              />
              Tambah Banner
            </button>
          </div>
          <div className="card-toolbar">
            {isModalOpen && (
              <AddBannerModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                handleAddBanner={handleAddBanner}
              />
            )}
            {isUpdateModalOpen && (
              <UpdateBannerModal
                isOpen={isUpdateModalOpen}
                onClose={handleUpdateCloseModal}
                handleUpdateBanner={handleUpdateBanner}
                id={selectedCategoryId}
                banner={bannerData.find(
                  (banner) => banner.id === selectedCategoryId
                )}
              />
            )}
          </div>
          <div className="d-flex align-items-center position-relative">
            <SearchInput placeholder="Search" onSearch={handleSearch} />
          </div>
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            <table className="table align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bold text-muted bg-light text-center">
                  <th className="min-w-100px text-center align-middle rounded-start">
                    No
                  </th>
                  <th className="min-w-125px text-center align-middle">
                    Gambar
                  </th>
                  <th className="min-w-125px text-center align-middle">URL</th>
                  <th className="min-w-100px text-center align-middle rounded-end">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      <Spin size="default" />
                    </td>
                  </tr>
                ) : bannerData.length > 0 ? (
                  bannerData.map((banner, index) => (
                    <tr key={banner.id}>
                      <td className="text-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="d-flex justify-content-center flex-column">
                            <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                              {index + 1}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td className="text-center">
                        <div className="symbol symbol-150px">
                          <span className="symbol-label bg-light">
                            <img
                              src={banner.photo}
                              className="h-100 rounded"
                              alt=""
                              style={{
                                objectFit: "cover",
                                aspectRatio: "16/9",
                              }}
                            />
                          </span>
                        </div>
                      </td>

                      <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="d-flex justify-content-start flex-column">
                            <span className="text-dark fw-bold">
                              {banner.url}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td className="text-center">
                        <button
                          onClick={() => {
                            setSelectedCategoryId(banner.id);
                            handleUpdateOpenModal();
                          }}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-2"
                        >
                          <KTSVG
                            path="/media/icons/duotune/art/art005.svg"
                            className="svg-icon-3"
                          />
                        </button>
                        <button
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target={`#kt_modal_${banner.id}`}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-3"
                          />
                        </button>
                        <DeleteModal
                          id={banner.id}
                          name={"Banner"}
                          onDelete={handleDeleteUser}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      {zeroData ? (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{ height: 60 }}
                          description={
                            <span className="text-muted fw-bold">
                              {zeroData}
                            </span>
                          }
                        />
                      ) : null}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              totalRecords={metaData.total}
              recordsPerPage={metaData.per_page}
              currentPage={metaData.page}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { BannerComponents };
