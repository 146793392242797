import React from "react";

interface PaginationProps {
  totalRecords: number;
  recordsPerPage: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
  onRecordsPerPageChange: (recordsPerPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalRecords,
  recordsPerPage,
  currentPage,
  onPageChange,
  onRecordsPerPageChange,
}) => {
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const pageNumbers: number[] = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    if (totalPages <= 8) {
      return pageNumbers;
    }

    const pages: number[] = [];
    let startPage = Math.max(1, currentPage - 3);
    let endPage = Math.min(totalPages, currentPage + 4);

    if (currentPage <= 4) {
      startPage = 1;
      endPage = 8;
    } else if (currentPage + 3 >= totalPages) {
      startPage = totalPages - 7;
      endPage = totalPages;
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <div className="d-flex flex-stack flex-wrap pt-10 pb-8">
      <div className="d-flex align-items-center">
        <div className="me-4">
          <select
            className="form-select form-select-solid form-select-sm"
            value={recordsPerPage}
            onChange={(e) => onRecordsPerPageChange(parseInt(e.target.value))}
          >
            {[10, 50, 100, 999999].map((size) => (
              <option key={size} value={size}>
                {size === 999999 ? "All" : size}
              </option>
            ))}
          </select>
        </div>
        <div className="fs-6 fw-semibold text-gray-700">
          Menampilkan {recordsPerPage * (currentPage - 1) + 1} -{" "}
          {Math.min(recordsPerPage * currentPage, totalRecords)} dari{" "}
          {totalRecords} data
        </div>
      </div>

      <ul
        className="pagination ms-3"
        style={{
          overflowX: "auto",
          whiteSpace: "nowrap",
          WebkitOverflowScrolling: "touch",
        }}
      >
        <li
          className={`page-item previous ${
            currentPage === 1 ? "disabled" : ""
          }`}
          style={{ cursor: currentPage === 1 ? "default" : "pointer" }}
        >
          <a
            className="page-link"
            onClick={() => onPageChange(currentPage - 1)}
          >
            <i className="previous" />
          </a>
        </li>
        {renderPageNumbers().map((number) => (
          <li
            key={number}
            className={`page-item ${number === currentPage ? "active" : ""}`}
            style={{ cursor: number === currentPage ? "default" : "pointer" }}
          >
            <a className="page-link" onClick={() => onPageChange(number)}>
              {number}
            </a>
          </li>
        ))}
        <li
          className={`page-item next ${
            currentPage === totalPages ? "disabled" : ""
          }`}
          style={{ cursor: currentPage === totalPages ? "default" : "pointer" }}
        >
          <a
            className="page-link"
            onClick={() => onPageChange(currentPage + 1)}
          >
            <i className="next" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export { Pagination };
