import { Empty, message } from "antd";
import React from "react";
import { Offcanvas } from "react-bootstrap";

export interface Trace {
  status: string;
  detail: string;
  date: string;
}

export interface TrackingTransaction {
  id: number;
  resi: string;
  type: string;
  courier: string;
  service: string;
  trace: Trace[] | undefined;
}

interface TrackingDrawerProps {
  trackingTransaction: TrackingTransaction | null;
  show: boolean;
  onHide: () => void;
}

const TrackingDrawer: React.FC<TrackingDrawerProps> = ({
  trackingTransaction,
  show,
  onHide,
}) => {
  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      placement="end"
      className="offcanvas-end"
      style={{ width: "650px", maxWidth: "none" }}
    >
      <Offcanvas.Header>
        <div className="d-flex flex-column w-100">
          <div className="d-flex justify-content-between align-items-center w-100">
            <h2 className="fw-bolder m-0">Lacak Pesanan</h2>
            <button
              type="button"
              className="btn btn-sm btn-icon"
              onClick={onHide}
            >
              <div className="btn btn-sm btn-icon btn-active-color-danger">
                <i className="ki-duotone ki-cross-square fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </i>
              </div>
            </button>
          </div>
          <div className="separator separator mt-4"></div>
        </div>
      </Offcanvas.Header>

      <div className="modal-body p-6 pt-0">
        {trackingTransaction ? (
          <>
            <div className="card mb-4 text-center p-3 shadow-none mb-6">
              <div className="d-flex justify-content-between align-items-center">
                <div className="fw-bold">No. Resi</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-gray-600">
                    {trackingTransaction?.resi || "Belum ada resi"}
                  </div>
                  {trackingTransaction?.resi && (
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(trackingTransaction.resi);
                        message.success("No. Resi Berhasil Di Copy");
                      }}
                      className="btn btn-icon btn-sm ms-2"
                    >
                      <div className="btn btn-sm btn-icon btn-active-color-danger">
                        <i className="ki-duotone ki-some-files fs-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </div>

            {trackingTransaction.trace &&
            trackingTransaction.trace.length > 0 ? (
              <div className="timeline">
                {trackingTransaction.trace
                  .slice()
                  .reverse()
                  .map((item, index) => (
                    <div
                      key={index}
                      className="timeline-item d-flex align-items-start"
                    >
                      <div className="timeline-line"></div>

                      <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                        <i className="ki-duotone ki-cd fs-2 text-danger">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </div>

                      <div className="timeline-content">
                        <div className="fs-5 fw-bold mb-2">
                          {item.status} - {item.date}
                        </div>
                        <div className="fs-6 text-muted">{item.detail}</div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 60 }}
                description={
                  <span className="text-muted fw-bold">
                    Tidak ada data pelacakan
                  </span>
                }
              />
            )}
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
            description={
              <span className="text-muted fw-bold">
                Tidak ada informasi pelacakan
              </span>
            }
          />
        )}
      </div>
    </Offcanvas>
  );
};

export default TrackingDrawer;
