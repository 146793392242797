/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import usePageTitle from "../../modules/global/PageTitle";
import { CalendarComponents } from "../../modules/settings/calendar/CalendarComponents";

const CalendarPage: FC = () => (
  <>
    <div className="card mb-xl-10">
      <CalendarComponents className="mb-xl-8" />
    </div>
  </>
);

const Calendar: FC = () => {
  const intl = useIntl();
  usePageTitle("Kalender Baba");

  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <CalendarPage />
    </>
  );
};

export { Calendar };
