/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {EditVersion} from '../../modules/settings/Version/EditVersion'
import {BannerComponents} from '../../modules/settings/Banner/BannerComponents'
import usePageTitle from '../../modules/global/PageTitle'

const BannerPage: FC = () => (
  <>
    <div className='card mb-xl-10'>
      <BannerComponents className='mb-xl-8' />
    </div>
  </>
)

const BannerWrapper: FC = () => {
  const intl = useIntl()
  usePageTitle('Banner')

  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <BannerPage />
    </>
  )
}

export {BannerWrapper}
